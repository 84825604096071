import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from 'classnames/bind';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import GradeIcon from '@mui/icons-material/Grade';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import SimCardRoundedIcon from '@mui/icons-material/SimCardRounded';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import SimCardIcon from '@mui/icons-material/SimCard';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import LoyaltyTwoToneIcon from '@mui/icons-material/LoyaltyTwoTone';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowCircleUpSharpIcon from '@mui/icons-material/ArrowCircleUpSharp';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LoopIcon from '@mui/icons-material/Loop';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ShareIcon from '@mui/icons-material/Share';
import FeedIcon from '@mui/icons-material/Feed';
import WifiFindRoundedIcon from '@mui/icons-material/WifiFindRounded';

import { ThemeContext } from '../../../component/ThemeContext/ThemeContext';
import { ButtonUI, FrameInfoSim } from '../../../component/CustomerMUI';
import styles from '../Home.module.scss';
import images from '../../../assets/images';
import ThemeLoad from '../../../component/CustomerMUI/ThemeLoad/ThemeLoad';
import { DataSim } from '../../../utils/DataSim';
import { bgrCateSim } from '../../../utils/bgrCateSim';
import {
  numberFormatText,
  numberFormatDeleteDots,
  totalDiscount,
  numberFormat,
  setLocalStorage,
  getLocalStorage,
  removeSpecialCharacters,
  checkTokenDashboad,
  convertToSlug,
} from '../../../utils/helper';
import {
  AppBar,
  Avatar,
  Backdrop,
  Badge,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormControl,
  FormControlLabel,
  Hidden,
  IconButton,
  InputAdornment,
  InputBase,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { LogicCategoryShowName } from '../LogicSearch/LogicCategoryShowName';
import * as listSimService from '../../../services/listSim';
import * as userIDService from '../../../services/apiUsers';
import { Box } from '@mui/system';
import FlashSale from '../FlashSale';
import ImageExcel from '../ImageExcel';
import { arrayFormatCate } from '../../../utils/formatSim';
import { listNewsID } from '../../../services/apiNews';
import ThemeLoad12 from '../../../component/CustomerMUI/ThemeLoad/ThemeLoad12';
import FrameImageShop from '../../../component/CustomerMUI/FrameInfoSim/FrameImageShop';
import { API_URL_SIMSO } from '../../../config/config';
import SimpleSnackbar from '../../../component/CustomerMUI/FrameInfoSim/NotifiAddCart';

const cx = classNames.bind(styles);

const homeNetWork = [
  {
    titleNetWork: 'Mobifone',
    srcNetwork: images.logo_mb,
    width: '70px',
  },
  {
    titleNetWork: 'Viettel',
    srcNetwork: images.logo_vt,
    width: '70px',
  },
  {
    titleNetWork: 'Vinaphone',
    srcNetwork: images.logo_vn,
    width: '70px',
  },
  {
    titleNetWork: 'Itelecom',
    srcNetwork: images.logo_itel,
    width: '50px',
  },
  {
    titleNetWork: 'Vietnamobile',
    srcNetwork: images.logo_vnmb,
    width: '70px',
  },
  {
    titleNetWork: 'Wintel',
    srcNetwork: images.logo_wintel,
    width: '70px',
  },
];

const priceRange = [
  {
    id: 2,
    titlePrice: 'Dưới 1 triệu',
    value: '0,0.999',
  },
  {
    id: 3,
    titlePrice: '1 - 2 triệu',
    value: '1,2',
  },
  {
    id: 4,
    titlePrice: '2 - 3 triệu',
    value: '2,3',
  },
  {
    id: 5,
    titlePrice: '3 - 5 triệu',
    value: '3,5',
  },
  {
    id: 6,
    titlePrice: '5 - 10 triệu',
    value: '5,10',
  },
  {
    id: 7,
    titlePrice: '10 - 20 triệu',
    value: '10,20',
  },
  {
    id: 8,
    titlePrice: '20 - 50 triệu',
    value: '20,50',
  },
  {
    id: 9,
    titlePrice: '50 - 100 triệu',
    value: '50,100',
  },
  {
    id: 10,
    titlePrice: 'Trên 100 triệu',
    value: '100',
  },
];
const searchDauSoSim = [
  {
    dauso: '09*',
    titleDauSo: '09',
  },
  {
    dauso: '08*',
    titleDauSo: '08',
  },
  {
    dauso: '03*',
    titleDauSo: '03',
  },
  {
    dauso: '07*',
    titleDauSo: '07',
  },
  {
    dauso: '05*',
    titleDauSo: '05',
  },
];

const arrayMenhSim = [
  {
    title: 'Mệnh Mộc',
    link: 'moc',
  },
  {
    title: 'Mệnh Thủy',
    link: 'thuy',
  },
  {
    title: 'Mệnh Hỏa',
    link: 'hoa',
  },
  {
    title: 'Mệnh Thổ',
    link: 'tho',
  },
  {
    title: 'Mệnh Kim',
    link: 'kim',
  },
];

const soluongText = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
const columnText = [1, 2, 3, 4, 5];
const colorTextImage = [
  { name: 'red', color: '#ef0000' },
  { name: 'blue', color: '#0600ef' },
  { name: 'green', color: '#016066' },
  { name: 'black', color: '#000f10' },
  { name: 'origin', color: '#ff8400' },
];

const searchTranhso = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const favourite = (number) => {
  const last4Str = String(number).slice(-4);
  const last4Num = parseInt(last4Str);
  const tailnumbers = last4Num / 80;
  const surplus = getDecimalPart(tailnumbers);
  const totalHungCat = '0.' + surplus;
  const total = Math.round(parseFloat(totalHungCat) * 80);
  if (DataSim[total]['mucdo'] === 'Đại Cát' || DataSim[total]['mucdo'] === 'Cát') {
    return DataSim[total];
  } else {
    return '';
  }
};

const getDecimalPart = (num) => {
  // console.log(Number.isInteger(num), num);
  if (Number.isInteger(num)) {
    return 0;
  }

  const decimalStr = num.toString().split('.')[1];
  return parseInt(decimalStr);
};
function HomeTwo() {
  const elementRef = useRef();
  const navigate = useNavigate();
  const hostname = window.location.hostname;
  const getToken = getLocalStorage('token');
  const { menhPs, catePs, mangPs, rangePs, search, dangsimPs, tranhso, istranhso, thuebaoPs } = useParams();

  const [simInfinteResult, setSimInfinteResult] = useState({ data: [] });
  const [simInfinteResultCount, setSimInfinteResultCount] = useState({ totalRows: 0 });
  const [menuSimResult, setMenuSimResult] = useState([]);
  const [page, setPage] = useState(1);
  const [loadinglist, setLoadingList] = useState(true);
  const [loadingTheme, setLoadingTheme] = useState(true);
  const [loadingTotalSim, setLoadingTotalSim] = useState('Chọn Số Đẹp');
  const [actionBtnSort, setActionBtnSort] = useState('');
  const [actionRangePriceSim, setActionRangePriceSim] = useState('');
  const [actionRangePrice, setActionRangePrice] = useState({ startPrice: '', endPrice: '' });
  const [actionNetWork, setActionNetWork] = useState('');
  const [actionMenuCate, setActionMenuCate] = useState(0);
  const [actionFormatSim, setActionFormatSim] = useState(0);
  const [actionMenhSim, setActionMenhSim] = useState(0);
  const [searchDauSim, setSearchDauSim] = useState('');
  const [textSearch, setTextSearch] = useState('');
  const [textSearchChange, setTextSearchChange] = useState('');
  const [actionTranhso, setActionTranhso] = useState([]);
  const [isSttTranhso, setIsSttTranhso] = useState(1); // 1 - tranh 6 số
  const [actionSimStart, setActionSimStart] = useState({ sodau: '', totalSoDau: '' });
  const [actionSimEnd, setActionSimEnd] = useState({ socuoi: '', totalSoCuoi: '' });
  const contextApiUser = useContext(ThemeContext);
  const loader = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [thuebao, setThuebao] = useState('');
  let textSimHeader = contextApiUser.textSearch;
  const [openCart, setOpenCart] = useState(false);

  const [open, setOpen] = useState(false);
  const [openRangePrice, setOpenRangePrice] = useState(false);
  const [pos, setPos] = useState(false);
  const [checkCoppy, setCheckCoppy] = useState({});
  const [isCopied, setIsCopied] = useState(false);
  const [textCopied, setTextCopied] = useState('');
  const [textCopiedSim, setTextCopiedSim] = useState('');
  const [textCopiedSimAndPrice, setTextCopiedSimAndPrice] = useState('');
  const [textCopiedChot, setTextCopiedChot] = useState('');
  const [textSimData, setTextSimData] = useState('');
  const [layoutSim, setLayoutSim] = useState('2');
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenDrawerCate, setIsOpenDrawerCate] = useState({ isCate: false, cateNumber: 0 });
  const [isCopyLink, setIsCopyLink] = useState('');
  const [iconCopy, setIconCopy] = useState('🍂');
  const [isShowCopyLink, setIsShowCopyLink] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [authDomain, setAuthDomain] = useState({});
  const [isSimDaily, setIsSimDaily] = useState(false);
  const [bgrDF, setBgrDF] = useState({ bgr: '', deg: '' });
  const [scroll, setScroll] = useState(0);
  const [isScroll, setIsScroll] = useState(true);
  const [isCloseImageMenhSim, setIsCloseImageMenhSim] = useState(false);
  const [isloadingCoppyAll, setIsloadingCoppyAll] = useState(false);
  const [dataImageExcel, setDataImageExcel] = useState([]);
  const [resultNews, setResultNews] = useState([]);
  const [resultShops, setResultShops] = useState([]);
  const [textDDMMYY, setTextDDMMYY] = useState({ day: '08', month: '08', year: '1988' });
  const [isCheckInFoIMG, setIsCheckInFoIMG] = useState({
    soluong: 45,
    color: 'blue',
    column: 3,
    isPrice: false,
    title: '',
  });
  const [openImage, setOpenImage] = useState(false);

  // const queryString = window.location.hash.replace(/^#\/home\?/, '');
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const mang = urlParams.get('mang');
  const text = urlParams.get('q');
  const loai = urlParams.get('loai');
  const dd = urlParams.get('dd');
  const range = urlParams.get('range');
  const order = urlParams.get('order');
  const menh = urlParams.get('menh');
  const ts = urlParams.get('ts');
  const ists = urlParams.get('ists');
  const tb = urlParams.get('tb');
  // console.log(useParams());

  const fetchApiUserID = async (resultAPISIM) => {
    // const resultAPISIM = await userIDService.listUserID(hostname);
    const items = JSON.parse(localStorage.getItem('items'));
    if (resultAPISIM.length > 0) {
      if (resultAPISIM[0]['authGuard'] === '1') {
        if (items) {
          const dataUser = {
            user: items.user,
            pass: items.pass,
            domain: hostname,
          };
          const checkLogin = await userIDService.checkLoginUsers(dataUser);
          if (checkLogin.totalRow > 0) {
            setAuthDomain(resultAPISIM[0]);
            setLocalStorage('khoso', resultAPISIM[0]);
            contextApiUser.toggleDataUser(resultAPISIM[0]);
            setLayoutSim(resultAPISIM[0]['layoutSim']);
            document.title = resultAPISIM[0]['TITLE_HOME'];
            if (resultAPISIM[0]['background']) {
              setBgrDF({
                bgr: resultAPISIM[0]['background']?.split(',')[0],
                deg: resultAPISIM[0]['background']?.split(',')[1],
              });
              document.documentElement.style.setProperty('--home-color', resultAPISIM[0]['background']?.split(',')[0]);
              document.documentElement.style.setProperty(
                '--home-color-deg',
                resultAPISIM[0]['background']?.split(',')[1],
              );
              document.body.style.backgroundColor = resultAPISIM[0]['background']?.split(',')[0] + '0f';
            }
          } else {
            navigate('/login', { replace: true });
          }
        } else {
          navigate('/login', { replace: true });
        }
      } else {
        setAuthDomain(resultAPISIM[0]);
        fetchApiNews(resultAPISIM[0]['id']);
        setLocalStorage('khoso', resultAPISIM[0]);
        contextApiUser.toggleDataUser(resultAPISIM[0]);
        setLayoutSim(resultAPISIM[0]['layoutSim']);
        document.title = resultAPISIM[0]['TITLE_HOME'];
        if (resultAPISIM[0]['background']) {
          setBgrDF({
            bgr: resultAPISIM[0]['background']?.split(',')[0],
            deg: resultAPISIM[0]['background']?.split(',')[1],
          });
          document.documentElement.style.setProperty('--home-color', resultAPISIM[0]['background']?.split(',')[0]);
          document.documentElement.style.setProperty('--home-color-deg', resultAPISIM[0]['background']?.split(',')[1]);
          document.body.style.backgroundColor = resultAPISIM[0]['background']?.split(',')[0] + '0f';
        }
      }
    }
  };

  const fetchApiSim = async () => {
    if (!isSimDaily) {
      setLoadingList(true);
      const resultAPISIM = await listSimService.listSimAll30(
        hostname,
        page,
        textSearch.length === 0 || textSearch.includes('*') ? textSearch : `*${textSearch}`,
        actionNetWork,
        actionMenuCate,
        actionFormatSim,
        actionMenhSim,
        actionBtnSort,
        actionRangePriceSim,
        isSttTranhso,
        actionTranhso.join(''),
      );
      if (resultAPISIM?.datauser?.length > 0) {
        fetchApiUserID(resultAPISIM?.datauser);
      }
      if (resultAPISIM?.datasim?.data?.length > 0) {
        setLoadingTheme(false);
        setLoadingList(false);
        setIsSimDaily(false);
        let arrResult = simInfinteResult?.data;
        setSimInfinteResult({
          data: arrResult.concat(resultAPISIM?.datasim?.data),
        });
        // fetchApiCountLocSim(
        //   textSearch,
        //   actionNetWork,
        //   actionMenuCate,
        //   actionFormatSim,
        //   actionMenhSim,
        //   actionRangePriceSim,
        //   actionTranhso.join(''),
        // );
      } else {
        if (textSearch && authDomain?.is_simdaily > 0) {
          searchSimDaily(textSearch, actionNetWork, '', actionRangePriceSim, actionBtnSort, simInfinteResult, page);
        } else {
          setLoadingTheme(false);
          setLoadingList(false);
        }
      }
    } else {
      if (textSearch && getLocalStorage('khoso')?.is_simdaily > 0) {
        searchSimDaily(textSearch, actionNetWork, '', actionRangePriceSim, actionBtnSort, simInfinteResult, page);
      } else {
        setLoadingTheme(false);
        setLoadingList(false);
      }
    }
  };

  const fetchApiNews = async (id) => {
    const resultAPISIM = await listNewsID(id);
    if (resultAPISIM && resultAPISIM.length > 0) {
      const arrNewsLimit10 = resultAPISIM.slice(0, 10);
      setResultNews(arrNewsLimit10);
    }
  };

  const fetchApiCateSim = async () => {
    const resultAPISIM = await listSimService.listCateSimAll(hostname);
    if (resultAPISIM['data'].length > 0) {
      setMenuSimResult(resultAPISIM.data);
    } else {
      setLoadingTheme(false);
      setLoadingList(false);
    }
  };

  //API LỌC SIM THEO ĐIỀU KIỆN
  const fetchApiLocSim = async (
    textsim = '',
    nhamang = '',
    theloai = '',
    dinhdang = '',
    menhsim = '',
    orderby = '',
    rangePrice = '',
    TranhsoSim = '',
    thuebaoSim = '',
    isLoadPage = '',
  ) => {
    checkLinkLocSim(textsim, nhamang, theloai, dinhdang, menhsim, orderby, rangePrice, TranhsoSim, thuebaoSim);
    setLoadingTheme(true);
    setLoadingList(true);
    setIsSimDaily(false);
    setSimInfinteResult({ data: [] });
    setSimInfinteResultCount({ totalRows: 0 });
    setPage(1);
    setIsloadingCoppyAll(false);

    const resultAPISIM = await listSimService.listSimAll30(
      hostname,
      1,
      textsim.length === 0 || textsim.includes('*') ? textsim : `*${textsim}`,
      nhamang,
      theloai,
      dinhdang,
      menhsim,
      orderby,
      rangePrice,
      isSttTranhso,
      TranhsoSim,
      thuebaoSim,
      isLoadPage,
    );
    if (resultAPISIM?.datauser?.length > 0) {
      fetchApiUserID(resultAPISIM?.datauser);
    }
    if (resultAPISIM?.datasim?.data?.length > 0) {
      setLoadingTheme(false);
      setLoadingList(false);
      setLoadingTotalSim('Đang tải số lượng sim...');
      let arrResult = [];
      setSimInfinteResult({
        data: arrResult.concat(resultAPISIM?.datasim?.data),
      });

      fetchApiCountLocSim(textsim, nhamang, theloai, dinhdang, menhsim, rangePrice, TranhsoSim, thuebaoSim);
    } else {
      if (textsim && getLocalStorage('khoso')?.is_simdaily > 0) {
        await searchSimDaily(textsim, nhamang, '', rangePrice, orderby, [], 1);
      } else {
        setLoadingTheme(false);
        setLoadingList(false);
        setIsloadingCoppyAll(false);
      }
    }
  };

  const fetchApiCountLocSim = async (
    textsim = '',
    nhamang = '',
    theloai = '',
    dinhdang = '',
    menhsim = '',
    rangePrice = '',
    TranhsoSim = '',
    thuebaoSim = '',
  ) => {
    let isparmas =
      textsim.length === 0 &&
      nhamang.length === 0 &&
      (theloai.length === 0 || theloai.length === undefined) &&
      (dinhdang.length === 0 || dinhdang.length === undefined) &&
      (menhsim.length === 0 || menhsim.length === undefined) &&
      rangePrice.length === 0 &&
      TranhsoSim.length === 0 &&
      thuebaoSim.length === 0;
    if (!isparmas) {
      const resultAPISIMTOTAL = await listSimService.listSimAllCount(
        hostname,
        textsim.length === 0 || textsim.includes('*') ? textsim : `*${textsim}`,
        nhamang,
        theloai,
        dinhdang,
        menhsim,
        rangePrice,
        isSttTranhso,
        TranhsoSim,
        thuebaoSim,
      );
      setLoadingTotalSim('');
      setIsloadingCoppyAll(false);
      setSimInfinteResultCount({
        totalRows: resultAPISIMTOTAL,
      });
    } else {
      setLoadingTotalSim('Kho Sim Đẹp');
      setIsloadingCoppyAll(false);
      setSimInfinteResultCount({
        totalRows: 0,
      });
    }
  };

  //tìm sim theo daily
  const searchSimDaily = async (keyword, nhamang = '', dauso = '', range = '', sapxep = '', dataSim, page) => {
    setLoadingList(true);
    let orderBySim = sapxep === 'ASC' ? 1 : sapxep === 'DESC' ? 2 : '';
    let nhamangdaily = '';
    switch (nhamang) {
      case 'Viettel':
        nhamangdaily = 1;
        break;
      case 'Vinaphone':
        nhamangdaily = 2;
        break;
      case 'Mobifone':
        nhamangdaily = 3;
        break;
      case 'Vietnamobile':
        nhamangdaily = 4;
        break;
      case 'Itelecom':
        nhamangdaily = 6;
        break;
      case 'Wintel':
        nhamangdaily = 7;
        break;

      default:
        nhamangdaily = '';
        break;
    }
    const resultAPISIM =
      removeSpecialCharacters(keyword).length === 10
        ? await listSimService.SimDailyDetail(keyword)
        : await listSimService.listSimDailyAll(keyword, nhamangdaily, dauso, range, orderBySim, page);
    if (resultAPISIM['data'].length > 0) {
      setLoadingTotalSim('');
      setLoadingTheme(false);
      setLoadingList(false);
      setIsSimDaily(true);
      setIsloadingCoppyAll(false);
      let arrResult = dataSim && dataSim?.data?.length > 0 ? dataSim?.data : [];
      setSimInfinteResult({
        data: arrResult.concat(resultAPISIM.data),
      });
      setSimInfinteResultCount({
        totalRows: removeSpecialCharacters(keyword).length === 10 ? 1 : 1000,
      });
    } else {
      setLoadingTheme(false);
      setLoadingList(false);
      setIsloadingCoppyAll(false);
    }
  };

  // hiển thị link đường dẫn
  const checkLinkLocSim = (
    textsim,
    nhamang,
    theloai,
    dinhdang,
    menhsim,
    orderby,
    rangePrice,
    TranhsoSim,
    thuebaoSim,
  ) => {
    if (
      textsim &&
      nhamang.length === 0 &&
      (theloai.length === 0 || theloai.length === undefined) &&
      (dinhdang.length === 0 || dinhdang.length === undefined) &&
      (menhsim.length === 0 || menhsim.length === undefined) &&
      rangePrice.length === 0 &&
      TranhsoSim.length === 0 &&
      orderby.length === 0 &&
      thuebaoSim.length === 0
    ) {
      navigate('/tim-sim/' + textsim, { replace: true });
    } else if (
      textsim.length === 0 &&
      nhamang.length > 0 &&
      (theloai.length === 0 || theloai.length === undefined) &&
      (dinhdang.length === 0 || dinhdang.length === undefined) &&
      (menhsim.length === 0 || menhsim.length === undefined) &&
      rangePrice.length === 0 &&
      TranhsoSim.length === 0 &&
      orderby.length === 0 &&
      thuebaoSim.length === 0
    ) {
      navigate('/sim-mang/' + nhamang, { replace: true });
    } else if (
      textsim.length === 0 &&
      nhamang.length === 0 &&
      theloai &&
      (dinhdang.length === 0 || dinhdang.length === undefined) &&
      (menhsim.length === 0 || menhsim.length === undefined) &&
      rangePrice.length === 0 &&
      TranhsoSim.length === 0 &&
      orderby.length === 0 &&
      thuebaoSim.length === 0
    ) {
      navigate('/sim-tat-ca/' + theloai, { replace: true });
    } else if (
      textsim.length === 0 &&
      nhamang.length === 0 &&
      (theloai.length === 0 || theloai.length === undefined) &&
      dinhdang.length > 0 &&
      (menhsim.length === 0 || menhsim.length === undefined) &&
      rangePrice.length === 0 &&
      TranhsoSim.length === 0 &&
      orderby.length === 0 &&
      thuebaoSim.length === 0
    ) {
      navigate('/sim-dang/' + dinhdang, { replace: true });
    } else if (
      textsim.length === 0 &&
      nhamang.length === 0 &&
      (theloai.length === 0 || theloai.length === undefined) &&
      (dinhdang.length === 0 || dinhdang.length === undefined) &&
      menhsim.length > 0 &&
      rangePrice.length === 0 &&
      TranhsoSim.length === 0 &&
      orderby.length === 0 &&
      thuebaoSim.length === 0
    ) {
      navigate('/sim-menh/' + menhsim, { replace: true });
    } else if (
      textsim.length === 0 &&
      nhamang.length === 0 &&
      (theloai.length === 0 || theloai.length === undefined) &&
      (dinhdang.length === 0 || dinhdang.length === undefined) &&
      (menhsim.length === 0 || menhsim.length === undefined) &&
      rangePrice.length === 0 &&
      TranhsoSim.length === 0 &&
      orderby.length === 0 &&
      thuebaoSim.length > 0
    ) {
      navigate('/thue-bao/' + thuebaoSim, { replace: true });
    } else if (
      textsim.length === 0 &&
      nhamang.length === 0 &&
      (theloai.length === 0 || theloai.length === undefined) &&
      (dinhdang.length === 0 || dinhdang.length === undefined) &&
      (menhsim.length === 0 || menhsim.length === undefined) &&
      rangePrice &&
      TranhsoSim.length === 0 &&
      orderby.length === 0 &&
      thuebaoSim.length === 0
    ) {
      navigate('/sim-theo-gia/' + rangePrice, { replace: true });
    } else if (
      textsim.length === 0 &&
      nhamang.length === 0 &&
      (theloai.length === 0 || theloai.length === undefined) &&
      (dinhdang.length === 0 || dinhdang.length === undefined) &&
      (menhsim.length === 0 || menhsim.length === undefined) &&
      rangePrice.length === 0 &&
      TranhsoSim.length > 0 &&
      orderby.length === 0 &&
      thuebaoSim.length === 0
    ) {
      navigate('/ts/' + TranhsoSim + '/' + isSttTranhso, { replace: true });
    } else if (
      textsim.length === 0 &&
      nhamang.length === 0 &&
      (theloai.length === 0 || theloai.length === undefined) &&
      (dinhdang.length === 0 || dinhdang.length === undefined) &&
      (menhsim.length === 0 || menhsim.length === undefined) &&
      rangePrice.length === 0 &&
      TranhsoSim.length === 0 &&
      orderby.length === 0 &&
      thuebaoSim.length === 0
    ) {
      navigate('/', { replace: true });
    } else {
      const textsimValue = (textsim && `&q=${textsim}`) || '';
      const nhamangValue = (nhamang && `&mang=${nhamang}`) || '';
      const theloaiValue = (theloai && `&loai=${theloai}`) || '';
      const dinhdangValue = (dinhdang && `&dd=${dinhdang}`) || '';
      const menhsimValue = (menhsim && `&menh=${menhsim}`) || '';
      const rangePriceValue = (rangePrice && `&range=${rangePrice}`) || '';
      const thuebaoValue = (thuebaoSim && `&tb=${thuebaoSim}`) || '';
      const orderbyValue = `?order=${orderby === 'ASC' ? 1 : orderby === 'DESC' ? 2 : ''}`;
      const tranhsoValue = (TranhsoSim.length > 0 && `&ists=${isSttTranhso}&ts=${TranhsoSim}`) || '';
      if (
        textsimValue ||
        nhamangValue ||
        theloaiValue ||
        dinhdangValue ||
        menhsimValue ||
        rangePriceValue ||
        tranhsoValue.length > 0 ||
        thuebaoValue
      ) {
        navigate(
          `/sim${orderbyValue}${textsimValue}${nhamangValue}${theloaiValue}${dinhdangValue}${menhsimValue}${rangePriceValue}${tranhsoValue}${thuebaoValue}`,
          { replace: true },
        );
      }
    }
  };

  useEffect(() => {
    if (page !== 1 && Math.ceil(simInfinteResultCount?.totalRows / 50) >= page) {
      setLoadingList(true);
      fetchApiSim();
    }
  }, [page]);

  const getParams = () => {
    let fmang = '';
    let ftext = '';
    let frange = '';
    let forder = '';
    let fmenh = '';
    let fcate = '';
    let fdinhdang = '';
    let fts = '';
    let ftb = '';

    if (mang || mangPs) {
      setActionNetWork(mang || mangPs);
      fmang = mang || mangPs;
    }
    if (text || search) {
      setTextSearch(text || search);
      setTextSearchChange(text || search);
      ftext = text || search;
    }
    if (loai || catePs) {
      setActionMenuCate(loai || catePs);
      fcate = loai || catePs;
    }
    if (dd || dangsimPs) {
      setActionFormatSim(dd || dangsimPs);
      fdinhdang = dd || dangsimPs;
    }
    if (range || rangePs) {
      setActionRangePriceSim(range || rangePs);
      setActionRangePrice({
        startPrice: (range && range.split(',')[0] * 1000000) || (rangePs && rangePs.split(',')[0] * 1000000),
        endPrice: (range && range.split(',')[1] * 1000000) || (rangePs && rangePs.split(',')[1] * 1000000),
      });
      frange = range || rangePs;
    }
    if (tb || thuebaoPs) {
      setThuebao(tb || thuebaoPs);
      ftb = tb || thuebaoPs;
    }
    if (order) {
      setActionBtnSort(order === '1' ? 'ASC' : order === 1 ? 'DESC' : '');
      forder = order === '1' ? 'ASC' : order === 1 ? 'DESC' : '';
    }
    if (menh || menhPs) {
      setActionMenhSim(menh || menhPs);
      fmenh = menh || menhPs;
    }
    if (ts || tranhso) {
      setIsSttTranhso(ists || istranhso || 1);
      fts = ts || tranhso;
      setActionTranhso(fts.toString().split('').map(Number));
    }

    fetchApiLocSim(ftext, fmang, fcate, fdinhdang, fmenh, forder, frange, fts, ftb, 1);
  };

  useEffect(() => {
    // fetchApiSim();
    apiListShop();
    fetchApiCateSim();
    getParams();
  }, []);

  // CHECK TEXT TRÊN HEADER
  useEffect(() => {
    if (textSimHeader?.isSim === 1) {
      setSearchDauSim(textSimHeader?.text);
      setTextSearch(textSimHeader?.text);
      setTextSearchChange(textSimHeader?.text);
      setCheckCoppy({});
      setDataImageExcel([]);
      if (textSimHeader?.text !== '') {
        fetchApiLocSim(
          textSimHeader?.text,
          actionNetWork,
          actionMenuCate,
          actionFormatSim,
          actionMenhSim,
          actionBtnSort,
          actionRangePriceSim,
          actionTranhso.join(''),
          thuebao,
        );
      }
    }
    if (textSimHeader?.isSim === 2) {
      navigate(`/shop?q=${textSimHeader?.text}`, { replace: true });
    }
  }, [textSimHeader?.text]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };

    // const observer = new IntersectionObserver(handleObserver, options);
    // if (loader.current) observer.observe(loader.current);

    // return () => observer.disconnect();
  }, [simInfinteResult, simInfinteResultCount]);

  // const handleObserver = (entities) => {
  //   const target = entities[0];
  //   if (target.isIntersecting && Math.ceil(simInfinteResultCount?.totalRows / 50) >= page) {
  //     setPage((page) => page + 1);
  //   }
  // };

  const handleDetailSim = (netWork, subCard, titleSim, cateSim, price, formatSim, menhSim) => {
    if (layoutSim === '1') {
      return [netWork && `Mạng: <b>${netWork}<b/>`, price && `Sale: ${numberFormatText(price)}`];
    } else {
      let arrFavourite = handleNameCate(titleSim, cateSim, menhSim, formatSim);
      return [netWork && `Mạng: <b>${netWork}<b/>`, arrFavourite, subCard && `Thuê bao: ${subCard}`];
    }
  };
  const handleNameCate = (titleSim, cateSim, menhSim, formatSim) => {
    let arrFavourite = '';
    if (favourite(titleSim)) {
      arrFavourite = `<b style='color: #d5001e'>Sim Đại Cát</b>`;
    } else if (menuSimResult.filter((x) => x.link === cateSim?.split(',')[0])?.length > 0) {
      arrFavourite = menuSimResult.filter((x) => x.link === cateSim?.split(',')[0])[0]?.title;
    } else if (LogicCategoryShowName(cateSim?.split(',')[0])?.length > 0) {
      arrFavourite = LogicCategoryShowName(cateSim.split(',')[0]);
    } else if (isSimDaily) {
      arrFavourite = cateSim;
    } else if (LogicCategoryShowName(menhSim?.split(',')[0])?.length > 0) {
      arrFavourite = LogicCategoryShowName(menhSim?.split(',')[0]);
    } else {
      arrFavourite = `Dạng ${actionFormatSim?.length > 0 ? actionFormatSim : formatSim?.split(',')[0]}`;
    }
    return arrFavourite;
  };
  // console.log('Home123', textSearch);

  // sort data network
  useEffect(() => {
    handleTop();
    setCheckCoppy([]);
    if (textSearch?.length === 0) {
      setSearchDauSim('');
    }
  }, []);

  useEffect(() => {
    const handleScroll = async () => {
      if (window.scrollY > 300) {
        if (!pos) setPos(true);
      } else {
        if (pos) setPos(false);
      }

      if (isFocused) {
        setIsScroll(true);
      } else {
        if (window.scrollY > scroll) {
          setScroll(window.scrollY);
          setIsScroll(false);
          setIsFocused(false);
        } else {
          setIsFocused(false);
          setIsScroll(true);
        }
      }
    };

    // setLoadingList(false);
    // window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);
    // Remove the event listener
    // return () => {
    //   elementRef.current.removeEventListener('scroll', handleScroll);
    // };
  }, [isFocused, scroll, page, simInfinteResult]);

  const handleClearSim = () => {
    setActionMenuCate(0);
    setActionNetWork('');
    setActionRangePrice({});
    setActionTranhso([]);
    setActionSimStart({ sodau: '', totalSoDau: '' });
    setActionSimEnd({ socuoi: '', totalSoCuoi: '' });
    setSearchDauSim('');
    setTextSearch('');
    setTextSearchChange('');
  };

  const handleChangeSelectDauSo = (event) => {
    setSearchDauSim(event.target.value);
    setTextSearch(event.target.value);
    setTextSearchChange(event.target.value);
    fetchApiLocSim(
      event.target.value,
      actionNetWork,
      actionMenuCate,
      actionFormatSim,
      actionMenhSim,
      actionBtnSort,
      actionRangePriceSim,
      actionTranhso.join(''),
    );
  };
  const handleChangeSelectDauSoDrawer = (value) => {
    setSearchDauSim(value);
    setTextSearch(value);
    setTextSearchChange(value);
    fetchApiLocSim(
      value,
      actionNetWork,
      actionMenuCate,
      actionFormatSim,
      actionMenhSim,
      actionBtnSort,
      actionRangePriceSim,
      actionTranhso.join(''),
    );
  };

  const handleClose = () => {
    setOpen(false);
    setOpenRangePrice(false);
    // setTextSearch('');
    // setTextSearchChange('');
    setOpenShare(false);
    setOpenImage(false);
    // contextSearch.toggleDataSim('');
  };

  // handle scroll to top
  const handleTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setPos(false);
  };

  const handleCoppyIcon = (e) => {
    handleTextCopiedSimAndPrice(checkCoppy, e.target.value);
    handleTextCopiedSim(checkCoppy, e.target.value);
  };

  // hanlde check coppy
  const handleCoppyAll = async (e) => {
    setIsScroll(true);
    setIsloadingCoppyAll(true);
    let arrsim = {};
    let arrData = [];
    if (e.target.checked) {
      simInfinteResult?.data?.map((sim, index) => {
        arrsim[index] = sim;
        arrData.push({ sim: sim.sim_show, price: numberFormatText(sim.price) });
      });
      setDataImageExcel(arrData);
      setCheckCoppy(arrsim);
      handleTextCopiedSimAndPrice(arrsim);
      handleTextCopied(arrsim);
      handleTextCopiedSim(arrsim);
      setIsloadingCoppyAll(false);
    } else {
      setCheckCoppy({});
      setDataImageExcel([]);
      setIsloadingCoppyAll(false);
    }
  };

  const handleCoppy = (index, sim) => {
    let state = { ...checkCoppy, [index]: sim };
    let arrData = [...dataImageExcel, { sim: sim.sim_show, price: numberFormatText(sim.price) }];
    setDataImageExcel(arrData);
    setCheckCoppy(state);
    handleTextCopiedSimAndPrice(state);
    handleTextCopied(state);
    handleTextCopiedSim(state);
    setIsScroll(true);
  };
  const handleDeleteCoppy = (index) => {
    let state = { ...checkCoppy };
    setDataImageExcel((prev) => {
      return [...prev.filter((item, i) => item.sim !== state[index]?.sim_show)];
    });
    delete state[index];
    setCheckCoppy(state);
    handleTextCopiedSimAndPrice(state);
    handleTextCopied(state);
    handleTextCopiedSim(state);
    setIsScroll(true);
  };
  const handleTextCopiedSim = (state, iconcopy = iconCopy) => {
    let listSim = Object.values(state);
    let arrSim = listSim.map((sim) => {
      let SimsCopied = `${iconcopy?.trim()}${sim.sim_show}`;

      return SimsCopied;
    });
    if (arrSim.length > 0) {
      const re = /,/gi;
      const sims = arrSim.toString().replace(re, '\n');
      setTextCopiedSim(sims);
    }
  };

  const handleTextCopiedSimAndPrice = (state, iconcopy = iconCopy) => {
    let listSim = Object.values(state);
    let arrSim = listSim.map((sim) => {
      let SimsCopied = `${iconcopy?.trim()}${sim.sim_show} = ${numberFormatText(sim.price)}`;

      return SimsCopied;
    });
    if (arrSim.length > 0) {
      const re = /,/gi;
      const sims = arrSim.toString().replace(re, '\n');
      setTextCopiedSimAndPrice(sims);
    }
  };

  const handleTextCopied = (state) => {
    let listSim = Object.values(state);
    var simSo = [];
    var simPrice = 0;
    let arrSim = listSim.map((sim) => {
      let SimsCopied = `- Sim: ${sim.sim_show}\n💰 Giá sale: ${numberFormatText(sim.price)} \n👉 Mạng: ${
        sim.nhamang
      } \n- Thuê bao: ${sim.thuebao}\n- Tình trạng: sim còn ${sim['note_sim'] ? '\n- ' + sim['note_sim'] : ''}  ${
        listSim.length > 1 ? `\n-----------` : ''
      } `;
      simSo.push(`- Sim mua: ${sim.sim_show}\n👉 Mạng: ${sim.nhamang} ${listSim.length > 1 ? `\n-----------` : ''}`);
      simPrice = numberFormatDeleteDots(simPrice) + numberFormatDeleteDots(sim.price);

      return SimsCopied;
    });
    if (arrSim.length > 0) {
      const re = /,/gi;
      const sims = arrSim.toString().replace(re, '\n');
      const simso = simSo.toString().replace(re, '\n');
      let textSimCopied = `THÔNG TIN CHI TIẾT SIM\n${sims}`;
      let textSimCopiedChot = `✈️𝑻𝒉𝒐̂𝒏𝒈 𝒕𝒊𝒏 𝑪𝒉𝒐̂́𝒕 𝑺𝒊𝒎!!!\n- Tên KH:\n${simso}\n- Liên hệ KH:\n- Địa chỉ Khách: \n ☛ Tổng thu KH : ${numberFormatText(
        simPrice.toString(),
      )}`;
      setTextCopied(textSimCopied);
      setTextCopiedChot(textSimCopiedChot);
    }
  };

  const submitBtnCopied = () => {
    setCheckCoppy({});
    setDataImageExcel([]);
    setTextCopied('');
    setTextCopiedSimAndPrice('');
    setTextCopiedChot('');
  };

  const handleLayoutSim = (e) => {
    setLayoutSim(`${e.target.value}`);
  };

  //thanh tim kiem nang cao
  const toggleDrawer = (open) => {
    setIsOpenDrawer(open);
  };
  const toggleDrawerCate = (open) => {
    setIsOpenDrawerCate({ ...isOpenDrawerCate, isCate: open });
  };

  // handle tranh so
  const handleTranhSo = (val) => {
    const i = actionTranhso.indexOf(val);
    if (i > -1) {
      const checkDelSo = actionTranhso.filter((item) => item !== val);
      setActionTranhso(checkDelSo);
    } else {
      const stateTS = [...actionTranhso, val];
      setActionTranhso(stateTS);
    }
  };
  const handleSttTranhso = (e) => {
    setIsSttTranhso(e.target.value);
    setActionTranhso([]);
  };

  // handle cắt chuỗi loại sim
  const handleCutCateTitle = (str) => {
    const array = str.split(' ');
    let title = '';
    if (array.length < 4) {
      title = `${array.length > 0 ? array[0] : ''} ${array.length >= 2 ? array[1] : ''} ${
        array.length === 3 ? array[2] : ''
      }`;
    } else {
      title = `${array[0]} ${array[1]} ${array[2]}...`;
    }
    return title;
  };

  const handleChangeSearchSim = (e) => {
    setCheckCoppy({});
    setDataImageExcel([]);
    setActionMenhSim(0);
    setActionMenuCate(0);
    setActionNetWork('');
    setActionRangePriceSim('');
    setActionTranhso([]);
    setSearchDauSim('');
    setActionFormatSim(0);
    setTextSearchChange(e.target.value);
    setTextSearch(e.target.value);
    setSimInfinteResult({ data: [] });
    setSimInfinteResultCount({ totalRows: 0 });
  };

  const onClickSearchSubmit = () => {
    setTextSearch(textSearchChange);
    setCheckCoppy({});
    setDataImageExcel([]);
    setActionMenhSim(0);
    setActionMenuCate(0);
    setActionNetWork('');
    setActionRangePriceSim('');
    setActionTranhso([]);
    setSearchDauSim('');
    setActionFormatSim(0);
    fetchApiLocSim(
      textSearchChange,
      actionNetWork,
      actionMenuCate,
      actionFormatSim,
      actionMenhSim,
      actionBtnSort,
      actionRangePriceSim,
      actionTranhso.join(''),
    );
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      setTextSearch(e.target.value);
      setCheckCoppy({});
      setDataImageExcel([]);
      setActionMenhSim(0);
      setActionMenuCate(0);
      setActionNetWork('');
      setActionRangePriceSim('');
      setActionTranhso([]);
      setSearchDauSim('');
      setActionFormatSim(0);
      fetchApiLocSim(
        textSearchChange,
        actionNetWork,
        actionMenuCate,
        actionFormatSim,
        actionMenhSim,
        actionBtnSort,
        actionRangePriceSim,
        actionTranhso.join(''),
      );
    }
  };

  const handleChangStartPrice = (e) => {
    setActionRangePrice({ ...actionRangePrice, startPrice: e.target.value });
    setActionRangePriceSim(
      (numberFormatDeleteDots(numberFormatDeleteDots(e.target.value)) / 1000000 || 0) +
        ',' +
        (numberFormatDeleteDots(actionRangePrice?.endPrice) / 1000000 || 0),
    );
  };

  const handleChangEndPrice = (e) => {
    setActionRangePrice({ ...actionRangePrice, endPrice: e.target.value });
    setActionRangePriceSim(
      (numberFormatDeleteDots(actionRangePrice?.startPrice) / 1000000 || 0) +
        ',' +
        (numberFormatDeleteDots(e.target.value) / 1000000 || 0),
    );
  };

  const handleSearchRangePrice = () => {
    setOpenRangePrice(false);
    fetchApiLocSim(
      textSearch,
      actionNetWork,
      actionMenuCate,
      actionFormatSim,
      actionMenhSim,
      actionBtnSort,
      actionRangePriceSim,
      actionTranhso.join(''),
    );
  };

  const resetSearchRangePriceDialog = () => {
    setActionRangePriceSim('');
    setActionRangePrice({ startPrice: '', endPrice: '' });
    fetchApiLocSim(
      textSearch,
      actionNetWork,
      actionMenuCate,
      actionFormatSim,
      actionMenhSim,
      actionBtnSort,
      '',
      actionTranhso.join(''),
    );
  };

  const handleIsInFoImage = (value) => {
    const arrIsImage = { ...isCheckInFoIMG, ...value };
    setIsCheckInFoIMG(arrIsImage);
  };

  const handleIsFocus = (isFocused) => {
    setIsFocused(true);
    setIsScroll(true);
  };

  const fetchApiLocSimNamSinh = async (
    nhamang = '',
    theloai = '',
    dinhdang = '',
    menhsim = '',
    orderby = '',
    rangePrice = '',
    TranhsoSim = '',
    thuebaoSim = '',
    isLoadPage = '',
  ) => {
    const textsim = textDDMMYY.day + textDDMMYY.month + textDDMMYY.year;
    setLoadingTheme(true);
    setLoadingList(true);
    setIsSimDaily(false);
    setSimInfinteResult({ data: [] });
    setSimInfinteResultCount({ totalRows: 0 });
    setPage(1);
    setIsloadingCoppyAll(false);

    let resultAPISIM = {};

    const resultAPISIMONE = await listSimService.listSimAll(
      hostname,
      1,
      textsim.length === 0 || textsim.includes('*') ? textsim : `*${textsim}`,
      nhamang,
      theloai,
      dinhdang,
      menhsim,
      orderby,
      rangePrice,
      isSttTranhso,
      TranhsoSim,
      thuebaoSim,
      isLoadPage,
    );

    if (resultAPISIMONE?.datasim?.data?.length === 0) {
      const textsimone = textDDMMYY.day + textDDMMYY.month + textDDMMYY.year.slice(2, 4);
      const resultAPISIMTWO = await listSimService.listSimAll(
        hostname,
        1,
        textsimone.length === 0 || textsimone.includes('*') ? textsimone : `*${textsimone}`,
        nhamang,
        theloai,
        dinhdang,
        menhsim,
        orderby,
        rangePrice,
        isSttTranhso,
        TranhsoSim,
        thuebaoSim,
        isLoadPage,
      );

      let orderBySim = orderby === 'ASC' ? 1 : orderby === 'DESC' ? 2 : '';
      let nhamangdaily = '';
      switch (nhamang) {
        case 'Viettel':
          nhamangdaily = 1;
          break;
        case 'Vinaphone':
          nhamangdaily = 2;
          break;
        case 'Mobifone':
          nhamangdaily = 3;
          break;
        case 'Vietnamobile':
          nhamangdaily = 4;
          break;
        case 'Itelecom':
          nhamangdaily = 6;
          break;
        case 'Wintel':
          nhamangdaily = 7;
          break;

        default:
          nhamangdaily = '';
          break;
      }
      if (resultAPISIMTWO?.datasim?.data?.length === 0) {
        const textsimtwo = textDDMMYY.day + textDDMMYY.month + textDDMMYY.year;
        if (textsimtwo && getLocalStorage('khoso')?.is_simdaily > 0) {
          const resultAPISIM = await listSimService.listSimDailyAll(
            textsimtwo,
            nhamangdaily,
            '',
            rangePrice,
            orderBySim,
            1,
          );
          if (resultAPISIM['data'].length > 0) {
            setLoadingTotalSim('');
            setLoadingTheme(false);
            setLoadingList(false);
            setIsSimDaily(true);
            setIsloadingCoppyAll(false);
            let arrResult = [];
            setSimInfinteResult({
              data: arrResult.concat(resultAPISIM.data),
            });
            setSimInfinteResultCount({
              totalRows: removeSpecialCharacters(textsimtwo).length === 10 ? 1 : 1000,
            });
          } else {
            const textsimthree = textDDMMYY.day + textDDMMYY.month + textDDMMYY.year.slice(2, 4);
            const resultAPISIM = await listSimService.listSimDailyAll(
              textsimthree,
              nhamangdaily,
              '',
              rangePrice,
              orderBySim,
              1,
            );
            if (resultAPISIM['data'].length > 0) {
              setLoadingTotalSim('');
              setLoadingTheme(false);
              setLoadingList(false);
              setIsSimDaily(true);
              setIsloadingCoppyAll(false);
              let arrResult = [];
              setSimInfinteResult({
                data: arrResult.concat(resultAPISIM.data),
              });
              setSimInfinteResultCount({
                totalRows: removeSpecialCharacters(textsimthree).length === 10 ? 1 : 1000,
              });
            } else {
              setLoadingTheme(false);
              setLoadingList(false);
              setIsloadingCoppyAll(false);
            }
          }
        } else {
          setLoadingTheme(false);
          setLoadingList(false);
          setIsloadingCoppyAll(false);
        }
      } else {
        resultAPISIM = resultAPISIMTWO;
        checkLinkLocSim(textsimone, nhamang, theloai, dinhdang, menhsim, orderby, rangePrice, TranhsoSim, thuebaoSim);
      }
    } else {
      resultAPISIM = resultAPISIMONE;
      checkLinkLocSim(textsim, nhamang, theloai, dinhdang, menhsim, orderby, rangePrice, TranhsoSim, thuebaoSim);
    }

    if (resultAPISIM?.datauser?.length > 0) {
      fetchApiUserID(resultAPISIM?.datauser);
    }
    if (resultAPISIM?.datasim?.data?.length > 0) {
      setLoadingTheme(false);
      setLoadingList(false);
      setLoadingTotalSim('Đang tải số lượng sim...');
      let arrResult = [];
      setSimInfinteResult({
        data: arrResult.concat(resultAPISIM?.datasim?.data),
      });

      fetchApiCountLocSim(textsim, nhamang, theloai, dinhdang, menhsim, rangePrice, TranhsoSim, thuebaoSim);
    }
  };

  const handleSearchNAMSINH = () => {
    fetchApiLocSimNamSinh(
      actionNetWork,
      'nam-sinh',
      actionFormatSim,
      actionMenhSim,
      actionBtnSort,
      actionRangePriceSim,
      actionTranhso.join(''),
      thuebao,
    );
  };

  const handleSubmitDataSim = () => {
    navigate(`/kiem-tra-goi-cuoc?tb=${textSimData}`, { replace: true });
  };
  const handleSubmitDataSimEnter = (e) => {
    if (e.key === 'Enter') {
      navigate(`/kiem-tra-goi-cuoc?tb=${e.target.value}`, { replace: true });
    }
  };

  const apiListShop = async () => {
    const resultApi = await listSimService.listShopSize(hostname, '', 1, 7);
    if (resultApi['data'].length > 0) {
      setResultShops(resultApi['data']);
    }
  };

  const submitSearchSim = () => {
    const textsimValue = (textSearchChange && `&q=${textSearchChange}`) || '';
    const nhamangValue = (actionNetWork && `&mang=${actionNetWork}`) || '';
    const theloaiValue = (actionMenuCate && `&loai=${actionMenuCate}`) || '';
    const dinhdangValue = (actionFormatSim && `&dd=${actionFormatSim}`) || '';
    const menhsimValue = (actionMenhSim && `&menh=${actionMenhSim}`) || '';
    const rangePriceValue = (actionRangePriceSim && `&range=${actionRangePriceSim}`) || '';
    const thuebaoValue = (thuebao && `&tb=${thuebao}`) || '';
    const orderbyValue = `?order=${actionBtnSort === 'ASC' ? 1 : actionBtnSort === 'DESC' ? 2 : ''}`;
    const tranhsoValue =
      (actionTranhso.join('').length > 0 && `&ists=${isSttTranhso}&ts=${actionTranhso.join('')}`) || '';
    navigate(
      `/sim${orderbyValue}${textsimValue}${nhamangValue}${theloaiValue}${dinhdangValue}${menhsimValue}${rangePriceValue}${tranhsoValue}${thuebaoValue}`,
      { replace: true },
    );
  };

  const submitShowShop = () => {
    navigate(`/shop`, { replace: true });
  };
  const redirectDetailShop = (title, id) => {
    navigate(`/shop/${convertToSlug(title)}.${id}`, { replace: true });
  };

  const onAddShop = (title, price, cate, soluong = 1, code) => {
    let obj = { title: title, price: price, cate: cate, soluong: soluong, code: code };
    let arrShop = getLocalStorage('addShop') || [];
    setLocalStorage('addShop', [...arrShop, obj]);
    setOpenCart(true);
    setTimeout(() => {
      setOpenCart(false);
    }, 3000);
  };

  return (
    <>
      <Container sx={{ p: 0, pb: '90px' }}>
        <Dialog open={openRangePrice} onClose={handleClose}>
          <DialogTitle id="alert-dialog-title" color={'red'}>
            {'Lọc sim theo khoảng giá'}
          </DialogTitle>
          <Grid container>
            <Grid container sx={{ m: 1 }}>
              <Grid item xs={6} className={cx('dis-center-1')}>
                <TextField
                  onChange={handleChangStartPrice}
                  value={actionRangePrice.startPrice ? numberFormat(actionRangePrice.startPrice) : ''}
                  label="giá từ"
                  variant="outlined"
                  error
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  onChange={handleChangEndPrice}
                  value={actionRangePrice.endPrice ? numberFormat(actionRangePrice.endPrice) : ''}
                  label="đến giá"
                  variant="outlined"
                  error
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1, ml: 1 }}>
              <h4>
                <i>Gợi ý sẵn</i>
              </h4>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              {priceRange.map((row, i) => (
                <ButtonUI
                  key={i}
                  sx={{ mb: 1, mr: 0.5, ml: 0.5 }}
                  startIcon={<MonetizationOnIcon />}
                  bgrColorBtn={actionRangePriceSim === row.value ? bgrDF.bgr + '26' : '#F3F4F6'}
                  colorText={actionRangePriceSim === row.value ? bgrDF.bgr : '#444444'}
                  borderBtn={actionRangePriceSim === row.value ? bgrDF.bgr + '4a' : '#4444444f'}
                  onClick={() => {
                    setActionRangePriceSim(actionRangePriceSim === row.value ? '' : row.value);
                    fetchApiLocSim(
                      textSearch,
                      actionNetWork,
                      actionMenuCate,
                      actionFormatSim,
                      actionMenhSim,
                      actionBtnSort,
                      actionRangePriceSim === row.value ? '' : row.value,
                      actionTranhso.join(''),
                    );
                    setOpenRangePrice(false);
                  }}
                >
                  {row.titlePrice}
                </ButtonUI>
              ))}
            </Grid>
          </Grid>
          <DialogActions>
            <ButtonUI onClick={handleClose}>Đóng</ButtonUI>
            <ButtonUI onClick={resetSearchRangePriceDialog} color="warning">
              Xóa Tìm Kiếm Hiện Tại
            </ButtonUI>
            <ButtonUI onClick={handleSearchRangePrice} color="error">
              Tìm Kiếm
            </ButtonUI>
          </DialogActions>
        </Dialog>
        <Fragment>
          <Drawer
            anchor={'right'}
            open={isOpenDrawer}
            onClose={() => toggleDrawer(false)}
            sx={{
              '& .MuiPaper-root': {
                borderTopLeftRadius: '10px',
              },
            }}
          >
            <label className={cx('title-sort-sim')}>
              <FilterAltRoundedIcon />
              <b>BỘ LỌC SIM</b>
            </label>
            <Box sx={{ width: 250, p: 1 }}>
              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>DANH MỤC SIM</b>
                </label>
                <FormControl size="small">
                  <Select
                    sx={{
                      borderRadius: 2.5,
                      fontSize: '0.8rem',
                      ':active': bgrDF.bgr + '4a',
                    }}
                    value={actionMenuCate}
                  >
                    <MenuItem
                      onClick={() => {
                        setActionMenuCate(0);
                      }}
                      value="0"
                    >
                      Loại sim
                    </MenuItem>
                    {menuSimResult.map((menu) => (
                      <MenuItem
                        key={menu.link}
                        value={menu.link}
                        onClick={() => {
                          setActionMenuCate(menu.link);
                        }}
                      >
                        {menu.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>MỆNH SIM</b>
                </label>
                <FormControl size="small">
                  <Select
                    sx={{
                      borderRadius: 2.5,
                      fontSize: '0.8rem',
                      ':active': bgrDF.bgr + '4a',
                    }}
                    value={actionMenhSim}
                  >
                    <MenuItem
                      onClick={() => {
                        setActionMenhSim(0);
                      }}
                      value="0"
                    >
                      Chọn Mệnh
                    </MenuItem>
                    {arrayMenhSim.map((menh) => (
                      <MenuItem
                        key={menh.link}
                        value={menh.link}
                        onClick={() => {
                          setActionMenhSim(menh.link);
                        }}
                      >
                        {menh.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>THUÊ BAO</b>
                </label>
                <FormControl size="small">
                  <Select
                    sx={{ borderRadius: 2.5, fontSize: '0.8rem' }}
                    value={thuebao}
                    onChange={(e) => {
                      setThuebao(e.target.value);
                    }}
                    displayEmpty
                  >
                    <MenuItem value="">Thuê bao</MenuItem>
                    <MenuItem value={'Trả trước'}>Trả trước</MenuItem>
                    <MenuItem value={'Trả sau'}>Trả sau</MenuItem>
                    <MenuItem value={'Trả trước cam kết'}>Trả trước cam kết</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>CHỌN MẠNG</b>
                </label>
                <Box spacing={1}>
                  {authDomain?.mang_show?.split(',')?.map((au, index) =>
                    homeNetWork.map(
                      (network, i) =>
                        au === network.titleNetWork && (
                          <ButtonUI
                            key={i}
                            borderRadius={6}
                            bgrColorBtn={actionNetWork === network.titleNetWork ? bgrDF.bgr + '26' : '#ffffff'}
                            borderBtn={actionNetWork === network.titleNetWork ? bgrDF.bgr : '#44444426'}
                            onClick={() => {
                              setActionNetWork(actionNetWork === network.titleNetWork ? '' : network.titleNetWork);
                            }}
                            sx={{ mr: 0.5, mb: 0.5 }}
                          >
                            {network.titleNetWork}
                          </ButtonUI>
                        ),
                    ),
                  )}
                </Box>
              </Stack>

              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>CHỌN KHOẢNG GIÁ</b>
                </label>
                <Stack direction={'row'} spacing={1}>
                  <TextField
                    onChange={handleChangStartPrice}
                    value={actionRangePrice.startPrice ? numberFormat(actionRangePrice.startPrice) : ''}
                    label="giá từ"
                    variant="outlined"
                    error
                    size="small"
                  />
                  <TextField
                    onChange={handleChangEndPrice}
                    value={actionRangePrice.endPrice ? numberFormat(actionRangePrice.endPrice) : ''}
                    label="đến giá"
                    variant="outlined"
                    error
                    size="small"
                  />
                </Stack>
                <Box spacing={1}>
                  {priceRange.map((price, index) => (
                    <ButtonUI
                      key={index}
                      borderRadius={6}
                      bgrColorBtn={actionRangePriceSim === price.value ? bgrDF.bgr + '26' : '#ffffff'}
                      borderBtn={actionRangePriceSim === price.value ? bgrDF.bgr : '#44444426'}
                      onClick={() => {
                        setActionRangePriceSim(actionRangePriceSim === price.value ? '' : price.value);
                        setActionRangePrice({
                          startPrice:
                            actionRangePriceSim === price.value
                              ? ''
                              : price.value && price.value.split(',')[0] * 1000000,
                          endPrice:
                            actionRangePriceSim === price.value
                              ? ''
                              : price.value && price.value.split(',')[1] * 1000000,
                        });
                      }}
                      sx={{ mr: 0.5, mb: 0.5 }}
                    >
                      {price.titlePrice}
                    </ButtonUI>
                  ))}
                </Box>
              </Stack>

              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>CHỌN ĐỊNH DẠNG SIM</b>
                </label>
                <ButtonUI
                  startIcon={<ClassOutlinedIcon />}
                  bgrColorBtn={actionFormatSim !== 0 ? bgrDF.bgr + '26' : '#F3F4F6'}
                  colorText={actionFormatSim !== 0 ? bgrDF.bgr : '#444444'}
                  borderBtn={actionFormatSim !== 0 ? bgrDF.bgr + '4a' : '#3131313b'}
                  onClick={() => setIsOpenDrawerCate({ isCate: true, cateNumber: 2 })}
                >
                  {arrayFormatCate.filter((x) => x.link === actionFormatSim).length > 0
                    ? 'Định Dạng:' + arrayFormatCate.filter((x) => x.link === actionFormatSim)[0].title
                    : `Sim Định Dạng`}
                </ButtonUI>
              </Stack>

              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>ĐẦU SỐ</b>
                </label>
                <Box spacing={1}>
                  {searchDauSoSim.map((dauso, index) => (
                    <ButtonUI
                      key={index}
                      borderRadius={6}
                      bgrColorBtn={searchDauSim == dauso.dauso ? bgrDF.bgr + '26' : '#ffffff'}
                      borderBtn={searchDauSim == dauso.dauso ? bgrDF.bgr : '#44444426'}
                      onClick={() => {
                        setSearchDauSim(dauso.dauso === searchDauSim ? '' : dauso.dauso);
                        setTextSearch(dauso.dauso === searchDauSim ? '' : dauso.dauso);
                        setTextSearchChange(dauso.dauso === searchDauSim ? '' : dauso.dauso);
                      }}
                      sx={{ mr: 0.5, mb: 0.5 }}
                    >
                      {dauso.titleDauSo}
                    </ButtonUI>
                  ))}
                </Box>
              </Stack>

              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>TRÁNH SỐ</b>
                </label>
                <Stack direction="row" spacing={1}>
                  <RadioGroup value={isSttTranhso} onChange={handleSttTranhso} row>
                    <FormControlLabel
                      value="1"
                      control={<Radio size="small" />}
                      label={<Typography sx={{ fontSize: 14 }}>Tránh 6 sô</Typography>}
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio size="small" />}
                      label={<Typography sx={{ fontSize: 14 }}>Tránh tất cả</Typography>}
                    />
                  </RadioGroup>
                </Stack>
                <Box spacing={1}>
                  {searchTranhso.map((sim, index) => (
                    <ButtonUI
                      key={index}
                      borderRadius={6}
                      bgrColorBtn={actionTranhso.indexOf(sim) > -1 ? bgrDF.bgr + '26' : '#ffffff'}
                      borderBtn={actionTranhso.indexOf(sim) > -1 ? bgrDF.bgr : '#44444426'}
                      onClick={() => handleTranhSo(sim)}
                      sx={{ mr: 0.5, mb: 0.5 }}
                    >
                      {sim}
                    </ButtonUI>
                  ))}
                </Box>
              </Stack>

              {/* <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>TỔNG SỐ ĐẦU - SỐ CUỐI</b>
                </label>
                <Stack direction="row" spacing={1} marginBottom="10px" alignItems={'center'}>
                  <label>Số đầu:</label>
                  <TextField
                    label="vd: 5"
                    variant="outlined"
                    size="small"
                    value={actionSimStart.sodau}
                    onChange={(e) => setActionSimStart({ ...actionSimStart, sodau: e.target.value })}
                  />
                  <label>=</label>
                  <TextField
                    label="vd: 23"
                    variant="outlined"
                    size="small"
                    value={actionSimStart.totalSoDau}
                    onChange={(e) => setActionSimStart({ ...actionSimStart, totalSoDau: e.target.value })}
                  />
                </Stack>
                <Stack direction="row" spacing={1} marginBottom="10px" alignItems={'center'}>
                  <label>Số cuối:</label>
                  <TextField
                    label="vd: 5"
                    variant="outlined"
                    size="small"
                    value={actionSimEnd.socuoi}
                    onChange={(e) => setActionSimEnd({ ...actionSimEnd, socuoi: e.target.value })}
                  />
                  <label>=</label>
                  <TextField
                    label="vd: 36"
                    variant="outlined"
                    size="small"
                    value={actionSimEnd.totalSoCuoi}
                    onChange={(e) => setActionSimEnd({ ...actionSimEnd, totalSoCuoi: e.target.value })}
                  />
                </Stack>
              </Stack> */}
              <Stack direction="row" spacing={1} marginBottom="10px" justifyContent={'flex-end'}>
                <ButtonUI
                  startIcon={<CancelIcon />}
                  bgrColorBtn={bgrDF.bgr + '26'}
                  colorText={bgrDF.bgr}
                  borderBtn={bgrDF.bgr + '4a'}
                  onClick={() => {
                    setActionMenhSim(0);
                    setActionMenuCate(0);
                    setActionNetWork('');
                    setActionFormatSim(0);
                    setTextSearch('');
                    setActionRangePriceSim('');
                    setActionTranhso([]);
                    setSearchDauSim('');
                    setTextSearch('');
                    setTextSearchChange('');
                    setCheckCoppy({});
                    setDataImageExcel([]);
                    setActionRangePrice({
                      startPrice: '',
                      endPrice: '',
                    });
                    fetchApiLocSim('', '', 0, '', 0, actionBtnSort, '', [], '');
                  }}
                >
                  RESET LẠI
                </ButtonUI>
                <ButtonUI
                  color="error"
                  borderBtn="#ff0000"
                  borderRadius={4}
                  sx={{ mr: 0.5, mb: 0.5 }}
                  onClick={() => {
                    toggleDrawer(false);
                    fetchApiLocSim(
                      textSearch,
                      actionNetWork,
                      actionMenuCate === 0 ? '' : actionMenuCate,
                      actionFormatSim,
                      actionMenhSim,
                      actionBtnSort,
                      actionRangePriceSim,
                      actionTranhso.join(''),
                      thuebao,
                    );
                  }}
                >
                  XEM KẾT QUẢ
                </ButtonUI>
              </Stack>
            </Box>
          </Drawer>
        </Fragment>

        <Fragment>
          <Drawer
            anchor={'bottom'}
            open={isOpenDrawerCate.isCate}
            onClose={() => toggleDrawerCate(false)}
            sx={{
              '& .MuiPaper-root': {
                borderTopLeftRadius: '10px',
              },
            }}
          >
            {isOpenDrawerCate.cateNumber === 1 && (
              <label className={cx('title-sort-sim')}>
                <DashboardCustomizeOutlinedIcon />
                <b>THỂ LOẠI SIM</b>
              </label>
            )}
            {isOpenDrawerCate.cateNumber === 2 && (
              <Stack
                direction={'row'}
                className={cx('title-sort-sim')}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <label className={cx('dis-center')}>
                  <ClassOutlinedIcon />
                  <b>SIM ĐỊNH DẠNG</b>
                </label>
                <b onClick={() => toggleDrawerCate(false)}>Đóng lại</b>
              </Stack>
            )}
            <Box sx={{ p: 1 }}>
              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>{isOpenDrawerCate.cateNumber === 1 && `CHỌN SỐ THEO THỂ LOẠI`}</b>
                  <b>{isOpenDrawerCate.cateNumber === 2 && `CHỌN SỐ THEO ĐỊNH DẠNG`}</b>
                </label>
                {isOpenDrawerCate.cateNumber === 1 && (
                  <Box>
                    {menuSimResult.map((menu, index) => (
                      <ButtonUI
                        key={index}
                        borderRadius={6}
                        bgrColorBtn={actionMenuCate === menu.link ? bgrDF.bgr + '26' : '#ffffff'}
                        borderBtn={actionMenuCate === menu.link ? bgrDF.bgr : '#44444426'}
                        onClick={() => {
                          setActionMenuCate(actionMenuCate === menu.link ? 0 : menu.link);
                          setCheckCoppy({});
                          setDataImageExcel([]);
                          fetchApiLocSim(
                            textSearch,
                            actionNetWork,
                            actionMenuCate === menu.link ? '' : menu.link,
                            actionFormatSim,
                            actionMenhSim,
                            actionBtnSort,
                            actionRangePriceSim,
                            actionTranhso.join(''),
                          );
                          toggleDrawerCate(false);
                        }}
                        sx={{ mr: 0.5, mb: 0.5 }}
                      >
                        {menu.title}
                      </ButtonUI>
                    ))}
                  </Box>
                )}
                {isOpenDrawerCate.cateNumber === 2 && (
                  <Box>
                    <Stack direction={'row'}>
                      <TextField
                        label="NHẬP DẠNG SIM"
                        id="outlined-start-adornment"
                        sx={{ m: 1 }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">{`A->H:`}</InputAdornment>,
                        }}
                        size="small"
                        defaultValue={actionFormatSim === 0 ? '' : actionFormatSim}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            setActionFormatSim(e.target.value);
                            setCheckCoppy({});
                            setDataImageExcel([]);
                            fetchApiLocSim(
                              textSearch,
                              actionNetWork,
                              actionMenuCate,
                              e.target.value,
                              actionMenhSim,
                              actionBtnSort,
                              actionRangePriceSim,
                              actionTranhso.join(''),
                            );
                            toggleDrawerCate(false);
                          }
                        }}
                      />
                    </Stack>
                    {arrayFormatCate.map((menu, index) => (
                      <ButtonUI
                        key={index}
                        borderRadius={6}
                        bgrColorBtn={actionFormatSim === menu.link ? bgrDF.bgr + '26' : '#ffffff'}
                        borderBtn={actionFormatSim === menu.link ? bgrDF.bgr : '#44444426'}
                        onClick={() => {
                          setActionFormatSim(actionFormatSim === menu.link ? 0 : menu.link);
                          fetchApiLocSim(
                            textSearch,
                            actionNetWork,
                            actionMenuCate,
                            actionFormatSim === menu.link ? '' : menu.link,
                            actionMenhSim,
                            actionBtnSort,
                            actionRangePriceSim,
                            actionTranhso.join(''),
                          );
                          toggleDrawerCate(false);
                          setCheckCoppy({});
                          setDataImageExcel([]);
                        }}
                        sx={{ mr: 0.5, mb: 0.5 }}
                      >
                        {menu.title}
                      </ButtonUI>
                    ))}
                  </Box>
                )}
              </Stack>
            </Box>
          </Drawer>
        </Fragment>

        <Grid container>
          <Grid item md={12} xs={12} sx={{ mt: 0.5 }}>
            {authDomain['background'] && layoutSim !== '8' && (
              <>
                <div className={cx('main-content-filter-layout2')}>
                  <div className={cx('main-content-filter-bgr')}>
                    <div className={cx('block-list-filter-layout2')}>
                      <div className={cx('box-list-subcate')}>
                        <div className={cx('list-subcate')}>
                          <Stack direction="row" spacing={1}>
                            <ButtonUI
                              startIcon={<FilterAltRoundedIcon />}
                              bgrColorBtn={bgrDF.bgr}
                              colorText={'#fff'}
                              borderBtn={bgrDF.bgr + '4a'}
                              onClick={() => {
                                toggleDrawer(true);
                                setCheckCoppy({});
                                setDataImageExcel([]);
                              }}
                            >
                              Bộ Lọc
                            </ButtonUI>

                            <FormControl size="small">
                              <Select
                                sx={{ borderRadius: 2.5, fontSize: '0.8rem' }}
                                value={searchDauSim}
                                onChange={(e) => {
                                  handleChangeSelectDauSo(e);
                                  setCheckCoppy({});
                                  setDataImageExcel([]);
                                }}
                                displayEmpty
                              >
                                <MenuItem value="">Đầu số</MenuItem>
                                <MenuItem value={'09*'}>Đầu số 09</MenuItem>
                                <MenuItem value={'07*'}>Đầu số 07</MenuItem>
                                <MenuItem value={'03*'}>Đầu số 03</MenuItem>
                                <MenuItem value={'08*'}>Đầu số 08</MenuItem>
                                <MenuItem value={'05*'}>Đầu số 05</MenuItem>
                              </Select>
                            </FormControl>

                            <ButtonUI
                              startIcon={<MonetizationOnIcon />}
                              bgrColorBtn={openRangePrice === true ? bgrDF.bgr + '26' : '#F3F4F6'}
                              colorText={openRangePrice === true ? bgrDF.bgr : '#444444'}
                              borderBtn={openRangePrice === true ? bgrDF.bgr + '4a' : '#3131313b'}
                              onClick={() => {
                                setOpenRangePrice(true);
                                setCheckCoppy({});
                                setDataImageExcel([]);
                              }}
                            >
                              {actionRangePriceSim
                                ? priceRange.find((price) => price.value === actionRangePriceSim)?.titlePrice ||
                                  numberFormatText(actionRangePrice.startPrice) +
                                    ' - ' +
                                    numberFormatText(actionRangePrice.endPrice)
                                : 'Khoảng giá'}
                            </ButtonUI>

                            {/* <FormControl size="small">
                              <Select
                                sx={{
                                  borderRadius: 2.5,
                                  fontSize: '0.8rem',
                                  ':active': bgrDF.bgr + '4a',
                                }}
                                value={actionMenuCate}
                              >
                                <MenuItem
                                  onClick={() => {
                                    setActionMenuCate(0);
                                    setCheckCoppy({});
                                    setDataImageExcel([]);
                                    fetchApiLocSim(
                                      textSearch,
                                      actionNetWork,
                                      '',
                                      actionFormatSim,
                                      actionMenhSim,
                                      actionBtnSort,
                                      actionRangePriceSim,
                                      actionTranhso.join(''),
                                      thuebao,
                                    );
                                  }}
                                  value="0"
                                >
                                  Loại sim
                                </MenuItem>
                                {menuSimResult.map((menu) => (
                                  <MenuItem
                                    key={menu.link}
                                    value={menu.link}
                                    onClick={() => {
                                      setActionMenuCate(menu.link);
                                      setCheckCoppy({});
                                      setDataImageExcel([]);
                                      fetchApiLocSim(
                                        textSearch,
                                        actionNetWork,
                                        menu.link,
                                        actionFormatSim,
                                        actionMenhSim,
                                        actionBtnSort,
                                        actionRangePriceSim,
                                        actionTranhso.join(''),
                                        thuebao,
                                      );
                                    }}
                                  >
                                    {menu.title}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl> */}

                            <ButtonUI
                              startIcon={<DashboardCustomizeOutlinedIcon />}
                              bgrColorBtn={actionMenuCate !== 0 ? bgrDF.bgr + '26' : '#F3F4F6'}
                              colorText={actionMenuCate !== 0 ? bgrDF.bgr : '#444444'}
                              borderBtn={actionMenuCate !== 0 ? bgrDF.bgr + '4a' : '#3131313b'}
                              onClick={() => setIsOpenDrawerCate({ isCate: true, cateNumber: 1 })}
                            >
                              {menuSimResult.filter((x) => x.link === actionMenuCate).length > 0
                                ? 'Loại: ' + menuSimResult.filter((x) => x.link === actionMenuCate)[0].title
                                : `Loại Sim`}
                            </ButtonUI>

                            <ButtonUI
                              startIcon={<ClassOutlinedIcon />}
                              bgrColorBtn={actionFormatSim !== 0 ? bgrDF.bgr + '26' : '#F3F4F6'}
                              colorText={actionFormatSim !== 0 ? bgrDF.bgr : '#444444'}
                              borderBtn={actionFormatSim !== 0 ? bgrDF.bgr + '4a' : '#3131313b'}
                              onClick={() => setIsOpenDrawerCate({ isCate: true, cateNumber: 2 })}
                            >
                              {arrayFormatCate.filter((x) => x.link === actionFormatSim).length > 0
                                ? 'Định Dạng:' + arrayFormatCate.filter((x) => x.link === actionFormatSim)[0].title
                                : `Sim Định Dạng`}
                            </ButtonUI>
                            <FormControl size="small">
                              <Select
                                sx={{
                                  borderRadius: 2.5,
                                  fontSize: '0.8rem',
                                  ':active': bgrDF.bgr + '4a',
                                }}
                                value={actionMenhSim}
                              >
                                <MenuItem
                                  onClick={() => {
                                    setActionMenhSim(0);
                                    setCheckCoppy({});
                                    setDataImageExcel([]);
                                    fetchApiLocSim(
                                      textSearch,
                                      actionNetWork,
                                      actionMenuCate,
                                      actionFormatSim,
                                      '',
                                      actionBtnSort,
                                      actionRangePriceSim,
                                      actionTranhso.join(''),
                                      thuebao,
                                    );
                                  }}
                                  value="0"
                                >
                                  Mệnh sim
                                </MenuItem>
                                {arrayMenhSim.map((menh) => (
                                  <MenuItem
                                    key={menh.link}
                                    value={menh.link}
                                    onClick={() => {
                                      setActionMenhSim(menh.link);
                                      setCheckCoppy({});
                                      setDataImageExcel([]);
                                      fetchApiLocSim(
                                        textSearch,
                                        actionNetWork,
                                        actionMenuCate,
                                        actionFormatSim,
                                        menh.link,
                                        actionBtnSort,
                                        actionRangePriceSim,
                                        actionTranhso.join(''),
                                        thuebao,
                                      );
                                    }}
                                  >
                                    {menh.title}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl size="small">
                              <Select
                                sx={{ borderRadius: 2.5, fontSize: '0.8rem' }}
                                value={thuebao}
                                onChange={(e) => {
                                  setThuebao(e.target.value);
                                  fetchApiLocSim(
                                    textSearch,
                                    actionNetWork,
                                    actionMenuCate,
                                    actionFormatSim,
                                    actionMenhSim,
                                    actionBtnSort,
                                    actionRangePriceSim,
                                    actionTranhso.join(''),
                                    e.target.value,
                                  );
                                  setCheckCoppy({});
                                  setDataImageExcel([]);
                                }}
                                displayEmpty
                              >
                                <MenuItem value="">Thuê bao</MenuItem>
                                <MenuItem value={'Trả trước'}>Trả trước</MenuItem>
                                <MenuItem value={'Trả sau'}>Trả sau</MenuItem>
                                <MenuItem value={'Trả trước cam kết'}>Trả trước cam kết</MenuItem>
                              </Select>
                            </FormControl>
                          </Stack>
                        </div>
                      </div>
                    </div>

                    <div className={cx('block-list-filter-layout2')}>
                      <div className={cx('box-list-subcate')}>
                        <div className={cx('list-subcate')}>
                          <Stack direction="row" spacing={1}>
                            {authDomain?.mang_show?.split(',')?.map((au, index) =>
                              homeNetWork.map(
                                (network, i) =>
                                  au === network.titleNetWork && (
                                    <ButtonUI
                                      key={i}
                                      borderRadius={6}
                                      bgrColorBtn={
                                        actionNetWork === network.titleNetWork ? bgrDF.bgr + '26' : '#ffffff'
                                      }
                                      borderBtn={actionNetWork === network.titleNetWork ? bgrDF.bgr : '#44444426'}
                                      onClick={() => {
                                        network.titleNetWork !== actionNetWork
                                          ? setActionNetWork(network.titleNetWork)
                                          : setActionNetWork('');
                                        fetchApiLocSim(
                                          textSearch,
                                          actionNetWork === network.titleNetWork ? '' : network.titleNetWork,
                                          actionMenuCate,
                                          actionFormatSim,
                                          actionMenhSim,
                                          actionBtnSort,
                                          actionRangePriceSim,
                                          actionTranhso.join(''),
                                        );
                                        setCheckCoppy({});
                                        setDataImageExcel([]);
                                      }}
                                    >
                                      <img src={network.srcNetwork} alt={network.titleNetWork} width={network.width} />
                                    </ButtonUI>
                                  ),
                              ),
                            )}
                          </Stack>
                        </div>
                      </div>
                    </div>

                    <div className={cx('block-list-filter-layout2')}>
                      <div className={cx('box-list-subcate')}>
                        <div className={cx('list-subcate')}>
                          <Stack direction="row" spacing={1}>
                            {priceRange.map((row, i) => (
                              <ButtonUI
                                key={i}
                                sx={{ mb: 1, mr: 0.5, ml: 0.5 }}
                                startIcon={<MonetizationOnIcon />}
                                bgrColorBtn={actionRangePriceSim === row.value ? bgrDF.bgr + '26' : '#F3F4F6'}
                                colorText={actionRangePriceSim === row.value ? bgrDF.bgr : '#444444'}
                                borderBtn={actionRangePriceSim === row.value ? bgrDF.bgr + '4a' : '#4444444f'}
                                onClick={() => {
                                  setActionRangePriceSim(actionRangePriceSim === row.value ? '' : row.value);
                                  fetchApiLocSim(
                                    textSearch,
                                    actionNetWork,
                                    actionMenuCate,
                                    actionFormatSim,
                                    actionMenhSim,
                                    actionBtnSort,
                                    actionRangePriceSim === row.value ? '' : row.value,
                                    actionTranhso.join(''),
                                  );
                                  setOpenRangePrice(false);
                                }}
                              >
                                {row.titlePrice}
                              </ButtonUI>
                            ))}
                          </Stack>
                        </div>
                      </div>
                    </div>

                    <div className={cx('block-list-filter-layout2')}>
                      <div className={cx('box-list-subcate')}>
                        <div className={cx('list-subcate')}>
                          <Stack direction="row" spacing={1} alignItems={'center'}>
                            <b>Sắp xếp:</b>
                            <ButtonUI
                              startIcon={<LocalFireDepartmentIcon />}
                              bgrColorBtn={actionBtnSort === '' ? bgrDF.bgr + '26' : '#F3F4F6'}
                              colorText={actionBtnSort === '' ? bgrDF.bgr : '#444444'}
                              borderBtn={actionBtnSort === '' ? bgrDF.bgr + '4a' : '#4444440d'}
                              onClick={() => {
                                setActionBtnSort('');
                                setCheckCoppy({});
                                setDataImageExcel([]);
                                fetchApiLocSim(
                                  textSearch,
                                  actionNetWork,
                                  actionMenuCate,
                                  actionFormatSim,
                                  actionMenhSim,
                                  '',
                                  actionRangePriceSim,
                                  actionTranhso.join(''),
                                  thuebao,
                                );
                              }}
                            >
                              Nổi Bật
                            </ButtonUI>
                            <ButtonUI
                              startIcon={<SouthIcon />}
                              bgrColorBtn={actionBtnSort === 'ASC' ? bgrDF.bgr + '26' : '#F3F4F6'}
                              colorText={actionBtnSort === 'ASC' ? bgrDF.bgr : '#444444'}
                              borderBtn={actionBtnSort === 'ASC' ? bgrDF.bgr + '4a' : '#4444440d'}
                              onClick={() => {
                                setActionBtnSort('ASC');
                                setCheckCoppy({});
                                setDataImageExcel([]);
                                fetchApiLocSim(
                                  textSearch,
                                  actionNetWork,
                                  actionMenuCate,
                                  actionFormatSim,
                                  actionMenhSim,
                                  'ASC',
                                  actionRangePriceSim,
                                  actionTranhso.join(''),
                                  thuebao,
                                );
                              }}
                            >
                              Giá Thấp
                            </ButtonUI>
                            <ButtonUI
                              startIcon={<NorthIcon />}
                              bgrColorBtn={actionBtnSort === 'DESC' ? bgrDF.bgr + '26' : '#F3F4F6'}
                              colorText={actionBtnSort === 'DESC' ? bgrDF.bgr : '#444444'}
                              borderBtn={actionBtnSort === 'DESC' ? bgrDF.bgr + '4a' : '#4444440d'}
                              onClick={() => {
                                setActionBtnSort('DESC');
                                setCheckCoppy({});
                                setDataImageExcel([]);
                                fetchApiLocSim(
                                  textSearch,
                                  actionNetWork,
                                  actionMenuCate,
                                  actionFormatSim,
                                  actionMenhSim,
                                  'DESC',
                                  actionRangePriceSim,
                                  actionTranhso.join(''),
                                  thuebao,
                                );
                              }}
                            >
                              Giá Cao
                            </ButtonUI>

                            {/* <ButtonUI borderBtn="#4444440d" startIcon={<LoopIcon />} onClick={fetchApiSimNew}>
                              Tải lại trang
                            </ButtonUI> */}
                          </Stack>
                        </div>
                      </div>
                    </div>
                    {(textSearch !== '' ||
                      actionMenuCate !== 0 ||
                      actionMenhSim !== 0 ||
                      actionNetWork !== '' ||
                      actionFormatSim !== 0 ||
                      thuebao !== '' ||
                      actionRangePriceSim !== '' ||
                      actionTranhso.length > 0) && (
                      <div className={cx('block-list-filter')}>
                        <label className={cx('block-list-filter_title')}>Đang tìm kiếm theo tiêu chí:</label>
                        <div className={cx('box-list-subcate')}>
                          <div className={cx('list-subcate')}>
                            <Stack direction="row" spacing={1}>
                              <ButtonUI
                                startIcon={<CancelIcon />}
                                bgrColorBtn={bgrDF.bgr + '26'}
                                colorText={bgrDF.bgr}
                                borderBtn={bgrDF.bgr + '4a'}
                                onClick={() => {
                                  setActionMenhSim(0);
                                  setActionMenuCate(0);
                                  setActionNetWork('');
                                  setActionFormatSim(0);
                                  setTextSearch('');
                                  setActionRangePriceSim('');
                                  setActionTranhso([]);
                                  setSearchDauSim('');
                                  setTextSearch('');
                                  setTextSearchChange('');
                                  setCheckCoppy({});
                                  setDataImageExcel([]);
                                  fetchApiLocSim('', '', 0, '', 0, actionBtnSort, '', [], '');
                                }}
                              >
                                Xóa tất cả
                              </ButtonUI>
                              {textSearch !== '' && (
                                <ButtonUI
                                  startIcon={<CancelIcon />}
                                  bgrColorBtn={bgrDF.bgr + '26'}
                                  colorText={bgrDF.bgr}
                                  borderBtn={bgrDF.bgr + '4a'}
                                  onClick={() => {
                                    setSearchDauSim('');
                                    setTextSearch('');
                                    setTextSearchChange('');
                                    setCheckCoppy({});
                                    setDataImageExcel([]);
                                    fetchApiLocSim(
                                      '',
                                      actionNetWork,
                                      actionMenuCate,
                                      actionFormatSim,
                                      actionMenhSim,
                                      actionBtnSort,
                                      actionRangePriceSim,
                                      actionTranhso.join(''),
                                      thuebao,
                                    );
                                    contextApiUser.toggleDataSim('');
                                  }}
                                >
                                  {`Tìm sim:` + textSearch}
                                </ButtonUI>
                              )}
                              {actionMenuCate !== 0 && (
                                <ButtonUI
                                  startIcon={<CancelIcon />}
                                  bgrColorBtn={bgrDF.bgr + '26'}
                                  colorText={bgrDF.bgr}
                                  borderBtn={bgrDF.bgr + '4a'}
                                  onClick={() => {
                                    setActionMenuCate(0);
                                    setCheckCoppy({});
                                    setDataImageExcel([]);
                                    fetchApiLocSim(
                                      textSearch,
                                      actionNetWork,
                                      0,
                                      actionFormatSim,
                                      actionMenhSim,
                                      actionBtnSort,
                                      actionRangePriceSim,
                                      actionTranhso.join(''),
                                      thuebao,
                                    );
                                  }}
                                >
                                  {menuSimResult.filter((x) => x.link === actionMenuCate).length > 0
                                    ? menuSimResult.filter((x) => x.link === actionMenuCate)[0].title
                                    : ``}
                                </ButtonUI>
                              )}
                              {actionMenhSim !== 0 && (
                                <ButtonUI
                                  startIcon={<CancelIcon />}
                                  bgrColorBtn={bgrDF.bgr + '26'}
                                  colorText={bgrDF.bgr}
                                  borderBtn={bgrDF.bgr + '4a'}
                                  onClick={() => {
                                    setActionMenhSim(0);
                                    setCheckCoppy({});
                                    setDataImageExcel([]);
                                    fetchApiLocSim(
                                      textSearch,
                                      actionNetWork,
                                      actionMenuCate,
                                      actionFormatSim,
                                      0,
                                      actionBtnSort,
                                      actionRangePriceSim,
                                      actionTranhso.join(''),
                                      thuebao,
                                    );
                                  }}
                                >
                                  {arrayMenhSim.filter((x) => x.link === actionMenhSim).length > 0
                                    ? arrayMenhSim.filter((x) => x.link === actionMenhSim)[0].title
                                    : ``}
                                </ButtonUI>
                              )}
                              {actionNetWork !== '' && (
                                <ButtonUI
                                  startIcon={<CancelIcon />}
                                  bgrColorBtn={bgrDF.bgr + '26'}
                                  colorText={bgrDF.bgr}
                                  borderBtn={bgrDF.bgr + '4a'}
                                  onClick={() => {
                                    setActionNetWork('');
                                    setCheckCoppy({});
                                    setDataImageExcel([]);
                                    fetchApiLocSim(
                                      textSearch,
                                      '',
                                      actionMenuCate,
                                      actionFormatSim,
                                      actionMenhSim,
                                      actionBtnSort,
                                      actionRangePriceSim,
                                      actionTranhso.join(''),
                                      thuebao,
                                    );
                                  }}
                                >
                                  {actionNetWork}
                                </ButtonUI>
                              )}
                              {actionFormatSim !== 0 && (
                                <ButtonUI
                                  startIcon={<CancelIcon />}
                                  bgrColorBtn={bgrDF.bgr + '26'}
                                  colorText={bgrDF.bgr}
                                  borderBtn={bgrDF.bgr + '4a'}
                                  onClick={() => {
                                    setActionFormatSim(0);
                                    setCheckCoppy({});
                                    setDataImageExcel([]);
                                    fetchApiLocSim(
                                      textSearch,
                                      actionNetWork,
                                      actionMenuCate,
                                      0,
                                      actionMenhSim,
                                      actionBtnSort,
                                      actionRangePriceSim,
                                      actionTranhso.join(''),
                                      thuebao,
                                    );
                                  }}
                                >
                                  {`Dạng: ${actionFormatSim}`}
                                </ButtonUI>
                              )}
                              {actionRangePriceSim !== '' && (
                                <ButtonUI
                                  startIcon={<CancelIcon />}
                                  bgrColorBtn={bgrDF.bgr + '26'}
                                  colorText={bgrDF.bgr}
                                  borderBtn={bgrDF.bgr + '4a'}
                                  onClick={() => {
                                    setActionRangePriceSim('');
                                    setCheckCoppy({});
                                    setDataImageExcel([]);
                                    fetchApiLocSim(
                                      textSearch,
                                      actionNetWork,
                                      actionMenuCate,
                                      actionFormatSim,
                                      actionMenhSim,
                                      actionBtnSort,
                                      '',
                                      actionTranhso.join(''),
                                      thuebao,
                                    );
                                  }}
                                >
                                  {actionRangePriceSim
                                    ? priceRange.find((price) => price.value === actionRangePriceSim)?.titlePrice ||
                                      numberFormatText(actionRangePrice.startPrice) +
                                        ' - ' +
                                        numberFormatText(actionRangePrice.endPrice)
                                    : 'Khoảng giá'}
                                </ButtonUI>
                              )}
                              {actionTranhso.length > 0 && (
                                <ButtonUI
                                  startIcon={<CancelIcon />}
                                  bgrColorBtn={bgrDF.bgr + '26'}
                                  colorText={bgrDF.bgr}
                                  borderBtn={bgrDF.bgr + '4a'}
                                  onClick={() => {
                                    setActionTranhso([]);
                                    setCheckCoppy({});
                                    setDataImageExcel([]);
                                    fetchApiLocSim(
                                      textSearch,
                                      actionNetWork,
                                      actionMenuCate,
                                      actionFormatSim,
                                      actionMenhSim,
                                      actionBtnSort,
                                      actionRangePriceSim,
                                      '',
                                      thuebao,
                                    );
                                  }}
                                >
                                  {`Tránh số: ${actionTranhso}`}
                                </ButtonUI>
                              )}
                              {thuebao !== '' && (
                                <ButtonUI
                                  startIcon={<CancelIcon />}
                                  bgrColorBtn={bgrDF.bgr + '26'}
                                  colorText={bgrDF.bgr}
                                  borderBtn={bgrDF.bgr + '4a'}
                                  onClick={() => {
                                    setActionTranhso([]);
                                    setCheckCoppy({});
                                    setDataImageExcel([]);
                                    fetchApiLocSim(
                                      textSearch,
                                      actionNetWork,
                                      actionMenuCate,
                                      actionFormatSim,
                                      actionMenhSim,
                                      actionBtnSort,
                                      actionRangePriceSim,
                                      actionTranhso.join(''),
                                      '',
                                    );
                                    setThuebao('');
                                  }}
                                >
                                  {`Thuê bao: ${thuebao}`}
                                </ButtonUI>
                              )}
                            </Stack>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className={cx('list-product')}>
                      <Stack direction="row" spacing={1}>
                        {isShowCopyLink && (
                          <CopyToClipboard text={isCopyLink} onCopy={() => setIsShowCopyLink(false)}>
                            <TextField
                              error
                              label="ấn sao chép link"
                              variant="outlined"
                              size="small"
                              value={isCopyLink}
                              disabled
                              fullWidth
                              sx={{ m: 1 }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <ContentCopyIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </CopyToClipboard>
                        )}
                      </Stack>
                    </div>
                  </div>
                </div>
              </>
            )}

            {authDomain['background'] && layoutSim === '8' && (
              <div className={cx('main-content-filter-layout2')}>
                <div className={cx('main-content-filter-bgr')}>
                  <div className={cx('block-list-filter-layout')}>
                    <div className={cx('box-list-subcate')}>
                      <div className={cx('list-subcate')}>
                        <Stack direction="row" spacing={1}>
                          {authDomain?.mang_show?.split(',')?.map((au, index) =>
                            homeNetWork.map(
                              (network, i) =>
                                au === network.titleNetWork && (
                                  <ButtonUI
                                    key={i}
                                    borderRadius={6}
                                    bgrColorBtn={actionNetWork === network.titleNetWork ? bgrDF.bgr + '26' : '#ffffff'}
                                    borderBtn={actionNetWork === network.titleNetWork ? bgrDF.bgr : '#44444426'}
                                    onClick={() => {
                                      network.titleNetWork !== actionNetWork
                                        ? setActionNetWork(network.titleNetWork)
                                        : setActionNetWork('');
                                      fetchApiLocSim(
                                        textSearch,
                                        actionNetWork === network.titleNetWork ? '' : network.titleNetWork,
                                        actionMenuCate,
                                        actionFormatSim,
                                        actionMenhSim,
                                        actionBtnSort,
                                        actionRangePriceSim,
                                        actionTranhso.join(''),
                                      );
                                      setCheckCoppy({});
                                      setDataImageExcel([]);
                                    }}
                                  >
                                    <img src={network.srcNetwork} alt={network.titleNetWork} width={network.width} />
                                  </ButtonUI>
                                ),
                            ),
                          )}
                        </Stack>
                      </div>
                    </div>
                  </div>
                  <div className={cx('block-list-filter-layout2')}>
                    <div className={cx('box-list-subcate')}>
                      <div className={cx('list-subcate')}>
                        <Stack direction="row" spacing={1} alignItems={'center'}>
                          <ButtonUI
                            startIcon={<FilterAltRoundedIcon />}
                            bgrColorBtn={bgrDF.bgr}
                            colorText={'#fff'}
                            borderBtn={bgrDF.bgr + '4a'}
                            onClick={() => {
                              toggleDrawer(true);
                              setCheckCoppy({});
                              setDataImageExcel([]);
                            }}
                          >
                            Bộ Lọc
                          </ButtonUI>
                          {/* <ButtonUI
                            startIcon={<LocalFireDepartmentIcon />}
                            bgrColorBtn={actionBtnSort === '' ? bgrDF.bgr + '26' : '#F3F4F6'}
                            colorText={actionBtnSort === '' ? bgrDF.bgr : '#444444'}
                            borderBtn={actionBtnSort === '' ? bgrDF.bgr + '4a' : '#4444440d'}
                            onClick={() => {
                              setActionBtnSort('');
                              setCheckCoppy({});
                              setDataImageExcel([]);
                              fetchApiLocSim(
                                textSearch,
                                actionNetWork,
                                actionMenuCate,
                                actionFormatSim,
                                actionMenhSim,
                                '',
                                actionRangePriceSim,
                                actionTranhso.join(''),
                                thuebao,
                              );
                            }}
                          >
                            Nổi Bật
                          </ButtonUI> */}
                          <ButtonUI
                            startIcon={<SouthIcon />}
                            bgrColorBtn={actionBtnSort === 'ASC' ? bgrDF.bgr + '26' : '#F3F4F6'}
                            colorText={actionBtnSort === 'ASC' ? bgrDF.bgr : '#444444'}
                            borderBtn={actionBtnSort === 'ASC' ? bgrDF.bgr + '4a' : '#4444440d'}
                            onClick={() => {
                              setActionBtnSort('ASC');
                              setCheckCoppy({});
                              setDataImageExcel([]);
                              fetchApiLocSim(
                                textSearch,
                                actionNetWork,
                                actionMenuCate,
                                actionFormatSim,
                                actionMenhSim,
                                'ASC',
                                actionRangePriceSim,
                                actionTranhso.join(''),
                                thuebao,
                              );
                            }}
                          >
                            Giá Thấp
                          </ButtonUI>
                          <ButtonUI
                            startIcon={<NorthIcon />}
                            bgrColorBtn={actionBtnSort === 'DESC' ? bgrDF.bgr + '26' : '#F3F4F6'}
                            colorText={actionBtnSort === 'DESC' ? bgrDF.bgr : '#444444'}
                            borderBtn={actionBtnSort === 'DESC' ? bgrDF.bgr + '4a' : '#4444440d'}
                            onClick={() => {
                              setActionBtnSort('DESC');
                              setCheckCoppy({});
                              setDataImageExcel([]);
                              fetchApiLocSim(
                                textSearch,
                                actionNetWork,
                                actionMenuCate,
                                actionFormatSim,
                                actionMenhSim,
                                'DESC',
                                actionRangePriceSim,
                                actionTranhso.join(''),
                                thuebao,
                              );
                            }}
                          >
                            Giá Cao
                          </ButtonUI>
                        </Stack>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <div className={cx('main-content-cate')}>
              <Grid container justifyContent={'center'} spacing={1}>
                <Grid item xs={6} md={2} onClick={() => setIsOpenDrawerCate({ isCate: true, cateNumber: 1 })}>
                  <Paper elevation={0}>
                    <Card variant="outlined" sx={{ borderRadius: 2 }}>
                      <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                        {' '}
                        <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                          <h3>THỂ LOẠI SIM</h3>
                          <DashboardCustomizeOutlinedIcon color="action" />
                        </Stack>
                        <Stack direction="column" spacing={0}>
                          <span>
                            {menuSimResult.filter((x) => x.link === actionMenuCate).length > 0
                              ? '-> ' + menuSimResult.filter((x) => x.link === actionMenuCate)[0].title
                              : `sim thần tài, taxi...`}
                          </span>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>

                <Grid item xs={6} md={2} onClick={() => setIsOpenDrawerCate({ isCate: true, cateNumber: 2 })}>
                  <Paper elevation={0}>
                    <Card variant="outlined" sx={{ borderRadius: 2 }}>
                      <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                        {' '}
                        <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                          <h3>SIM ĐỊNH DẠNG</h3>
                          <ClassOutlinedIcon color="action" />
                        </Stack>
                        <Stack direction="column" spacing={0}>
                          <span>
                            {arrayFormatCate.filter((x) => x.link === actionFormatSim).length > 0
                              ? '-> ' + arrayFormatCate.filter((x) => x.link === actionFormatSim)[0].title
                              : `abc.abc, ababac...`}
                          </span>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>

                <Grid item xs={6} md={3} component={Link} to="/ngu-hanh-tuong-sinh">
                  <Paper elevation={0}>
                    <Card variant="outlined" sx={{ borderRadius: 2 }}>
                      <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                        {' '}
                        <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                          <h3 className={cx('text-nowrap')}>SIM PHONG THỦY</h3>
                          <AutoAwesomeOutlinedIcon color="action" />
                        </Stack>
                        <Stack direction="column" spacing={0}>
                          <span>sim hợp tuổi năm sinh</span>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>
                <Hidden only={['sm', 'xs']}>
                  <Grid
                    item
                    xs={6}
                    md={3}
                    component={Link}
                    to={actionMenuCate === 'nam-sinh' ? '/' : '/sim-tat-ca/nam-sinh'}
                    onClick={() => {
                      setActionMenuCate(actionMenuCate === 'nam-sinh' ? 0 : 'nam-sinh');
                      setCheckCoppy({});
                      setDataImageExcel([]);
                      fetchApiLocSim(
                        textSearch,
                        actionNetWork,
                        actionMenuCate === 'nam-sinh' ? '' : 'nam-sinh',
                        actionFormatSim,
                        actionMenhSim,
                        actionBtnSort,
                        actionRangePriceSim,
                        actionTranhso.join(''),
                      );
                      toggleDrawerCate(false);
                    }}
                  >
                    <Paper elevation={0}>
                      <Card variant="outlined" sx={{ borderRadius: 2 }}>
                        <CardContent
                          sx={{ p: 1, '&:last-child': { pb: 1 } }}
                          className={cx(actionMenuCate === 'nam-sinh' ? 'active-cate-ns' : '')}
                        >
                          {' '}
                          <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                            <h3>SIM NĂM SINH</h3>
                            <CalendarMonthOutlinedIcon color="action" />
                          </Stack>
                          <Stack direction="column" spacing={0}>
                            <span>sim theo đuôi năm sinh</span>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid>
                </Hidden>

                <Grid item xs={6} md={2} component={Link} to="/bai-viet">
                  <Paper elevation={0}>
                    <Card variant="outlined" sx={{ borderRadius: 2 }}>
                      <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                        {' '}
                        <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                          <h3>TIN TỨC SIM</h3>
                          <ArticleRoundedIcon color="action" />
                        </Stack>
                        <Stack direction="column" spacing={0}>
                          <span>Chia sẻ kiến thức sim</span>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>
              </Grid>
            </div> */}

            {/* FLASH SALE  */}
            {location.pathname === '/' && location.search === '' && authDomain?.id && (
              <FlashSale id_user={authDomain?.id} />
            )}
          </Grid>

          {/* CỘT BÊN TRÁI */}
          {!authDomain['background'] && (
            <Hidden only={['sm', 'xs']}>
              <Grid item md={2}>
                <Grid item md={12}>
                  <div className={cx(`header-title-grey-layout2`)}>
                    <div className={cx(`header-title-item-grey-layout2`)}>
                      <SimCardIcon sx={{ pr: '5px' }} fontSize="small" /> <b> SIM THEO GIÁ</b>
                    </div>
                    {loadingTheme && <Skeleton variant="text" />}
                    {loadingTheme && <Skeleton variant="text" />}
                    {loadingTheme && <Skeleton variant="text" />}
                    {loadingTheme && <Skeleton variant="text" />}
                    {loadingTheme && <Skeleton variant="text" />}
                  </div>
                </Grid>
              </Grid>
            </Hidden>
          )}
          {authDomain['background'] && (
            <Hidden only={['sm', 'xs']}>
              <Grid item md={2}>
                <Grid item md={12}>
                  <div className={cx(`header-title-grey-layout2`)}>
                    <div className={cx(`header-title-item-grey-layout2`)}>
                      <SimCardIcon sx={{ pr: '5px' }} fontSize="small" /> <b> SIM THEO GIÁ</b>
                    </div>
                    {priceRange.map((price, index) => (
                      <div
                        key={index}
                        className={cx('detail-item-sim', `${actionRangePriceSim === price.value ? 'bgr-active' : ''}`)}
                        onClick={() => {
                          setActionRangePriceSim(actionRangePriceSim === price.value ? '' : price.value);
                          fetchApiLocSim(
                            textSearch,
                            actionNetWork,
                            actionMenuCate,
                            actionFormatSim,
                            actionMenhSim,
                            actionBtnSort,
                            actionRangePriceSim === price.value ? '' : price.value,
                            actionTranhso.join(''),
                            thuebao,
                          );
                        }}
                      >
                        <div className={cx('title-item')}>Sim {price.titlePrice}</div>
                      </div>
                    ))}
                  </div>

                  <div className={cx(`header-title-grey-layout2`)}>
                    <div className={cx(`header-title-item-grey-layout2`)}>
                      <SimCardIcon sx={{ pr: '5px' }} fontSize="small" /> <b> SIM ĐỊNH DẠNG</b>
                    </div>
                    {arrayFormatCate.map(
                      (menu, index) =>
                        index <= 35 && (
                          <div
                            key={index}
                            className={cx('detail-item-sim', `${actionMenuCate === menu.link ? 'bgr-active' : ''}`)}
                            onClick={() => {
                              menu.link !== actionFormatSim ? setActionFormatSim(menu.link) : setActionFormatSim('');
                              fetchApiLocSim(
                                textSearch,
                                actionNetWork,
                                actionMenuCate,
                                actionFormatSim === menu.link ? '' : menu.link,
                                actionMenhSim,
                                actionBtnSort,
                                actionRangePriceSim,
                                actionTranhso.join(''),
                                thuebao,
                              );
                            }}
                          >
                            <div className={cx('title-item')}>Sim {menu.title}</div>
                          </div>
                        ),
                    )}
                  </div>
                </Grid>
              </Grid>
            </Hidden>
          )}

          {/* CHI TIẾT SIM CỘT GIỮA */}
          <Grid item md={7} xs={12}>
            <div ref={elementRef} className={cx('wrapper-content')}>
              {!loadinglist &&
                hostname === 'sieuthivuongkhang.vn' &&
                search === undefined &&
                mangPs === undefined &&
                catePs === undefined &&
                dangsimPs === undefined &&
                menhPs === undefined &&
                rangePs === undefined &&
                thuebaoPs === undefined && (
                  <div className={cx(`header-title-grey-layout2`)}>
                    {/* <div>
                    <div className={cx(`header-title-item-grey-layout2`)}>
                      <WifiFindRoundedIcon sx={{ pr: '5px' }} fontSize="small" />{' '}
                      <b>
                        {' '}
                        KIỂM TRA GÓI CƯỚC <b style={{ color: 'blue' }}>VINAPHONE</b>
                      </b>
                    </div>
                  </div> */}
                    <div className={cx(`header-title-item-grey-layout2`)}>
                      <h1 className={cx('title-sim-new')}>
                        <div className={cx('dis-center')}>
                          <WifiFindRoundedIcon sx={{ mr: '3px' }} />
                          KIỂM TRA GÓI CƯỚC <b style={{ color: 'blue', marginLeft: 5 }}> VINAPHONE</b>
                        </div>
                      </h1>
                    </div>

                    <Grid item md={12} xs={12} sx={{ mt: 1, mb: 1 }}>
                      <div className={cx('wrapper-header')}>
                        <div className={cx('inner-header')}>
                          <Grid container>
                            <Grid item md={12} xs={12}>
                              <Paper
                                elevation={3}
                                sx={{ p: '0px 2px', display: 'flex', alignItems: 'center', borderRadius: 2 }}
                              >
                                <InputBase
                                  sx={{ ml: 1, flex: 1 }}
                                  placeholder={`Nhập số điện thoại`}
                                  inputProps={{ 'aria-label': 'Nhập số điện thoại' }}
                                  onChange={(e) => setTextSimData(e.target.value)}
                                  onKeyPress={handleSubmitDataSimEnter}
                                  fullWidth
                                />
                                {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                                <IconButton
                                  sx={{ m: '2px' }}
                                  className={cx('bgr-default')}
                                  aria-label="directions"
                                  onClick={handleSubmitDataSim}
                                >
                                  <SearchIcon sx={{ color: 'aliceblue' }} />
                                </IconButton>
                              </Paper>
                            </Grid>

                            <Grid item xs={12} sx={{ mt: 1 }}>
                              <label className={cx('color-trang')}>
                                <b>Nhập số thuê bao để kiểm tra gói cước data</b>
                              </label>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  </div>
                )}
              {resultShops.length > 0 &&
                search === undefined &&
                mangPs === undefined &&
                catePs === undefined &&
                dangsimPs === undefined &&
                menhPs === undefined &&
                rangePs === undefined &&
                thuebaoPs === undefined && (
                  <div className={cx(`header-title-grey-layout2`)}>
                    <div className={cx(`header-title-item-grey-layout2`)}>
                      <h1 className={cx('title-sim-new')}>
                        <div className={cx('dis-center')}>
                          <LocalMallIcon />
                          SẢN PHẨM HOT
                        </div>
                      </h1>
                    </div>

                    <Grid container sx={{ p: 1 }} spacing={1}>
                      {resultShops &&
                        resultShops?.slice(0, 6).map((item, i) => (
                          <Grid item md={4} xs={6} key={i}>
                            <FrameImageShop
                              image={`${API_URL_SIMSO}/imgshop?name=${item.image}.jpg`}
                              title={item.title}
                              price={item.price}
                              price_old={item.price_old}
                              product_code={item.product_code}
                              id={item.id}
                            />
                          </Grid>
                        ))}

                      {resultShops?.length > 6 && (
                        <Grid
                          container
                          className={cx('bounce2')}
                          sx={{ mt: 2, justifyContent: 'center', cursor: 'pointer' }}
                          onClick={submitShowShop}
                        >
                          <ArrowCircleDownRoundedIcon className={cx('color-cs')} sx={{ fontSize: '2.5rem' }} />
                        </Grid>
                      )}
                    </Grid>
                  </div>
                )}
              <div className={cx('main-content')}>
                <div>
                  {layoutSim !== '8' && (
                    <h1 className={cx('title-sim-new')}>
                      <div className={cx('dis-center')}>
                        <SimCardIcon />
                        {menuSimResult.filter((x) => x.link === actionMenuCate).length > 0
                          ? menuSimResult.filter((x) => x.link === actionMenuCate)[0].title
                          : `Sim Số Đẹp Mới Về`}
                      </div>
                    </h1>
                  )}
                  {authDomain['background'] && (
                    <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                      <b className={cx('dis-center')}>
                        {!isloadingCoppyAll ? (
                          <Checkbox
                            checked={Object.values(checkCoppy).length > 0 ? true : false}
                            onClick={handleCoppyAll}
                          />
                        ) : (
                          <CircularProgress className={cx('loading-custom')} />
                        )}
                        {!isSimDaily && simInfinteResultCount?.totalRows > 0 && loadingTotalSim === ''
                          ? `Chọn: ${simInfinteResult?.data?.length}/${simInfinteResultCount?.totalRows} sim`
                          : loadingTotalSim}
                      </b>

                      <b className={cx('layout-product')}>
                        Giao diện:
                        <FormControl sx={{ m: 0.5 }} size="small" error>
                          <Select
                            variant="standard"
                            sx={{ borderRadius: 2.5, fontSize: '0.8rem' }}
                            value={layoutSim}
                            onChange={handleLayoutSim}
                            displayEmpty
                          >
                            <MenuItem value={4}>Excel</MenuItem>
                            <MenuItem value={6}>chi tiết</MenuItem>
                            <MenuItem value={5}>thu gọn</MenuItem>
                            <MenuItem value={8}>mobile</MenuItem>
                          </Select>
                        </FormControl>
                      </b>
                    </Stack>
                  )}
                  <Grid container justifyContent={'center'} spacing={1}>
                    {/* Theme Layout load */}
                    {loadingTheme && <ThemeLoad />}
                    {loadingTheme && <ThemeLoad />}
                    <Backdrop
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                      open={loadingTheme}
                      onClick={handleClose}
                    >
                      <CircularProgress color="inherit" />
                      <b className={cx('loadingsim')}>Hệ thống đang tải dữ liệu sim ...</b>
                    </Backdrop>
                    {layoutSim === '5' &&
                      simInfinteResult &&
                      simInfinteResult?.data?.map(
                        (sim, index) =>
                          sim.sim && (
                            <Grid key={index} item xs={6} md={4}>
                              {/* {layoutSim === '1' && ( */}
                              <Paper elevation={0}>
                                <Card variant="outlined" sx={{ borderRadius: 3 }}>
                                  <CardContent
                                    sx={{ p: 0, '&:last-child': { pb: 0 } }}
                                    component={Link}
                                    to={`/s/${sim.sim}`}
                                  >
                                    <div className={cx('detail-item-price')}>
                                      <Stack
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                        justifyContent="space-around"
                                      >
                                        <Avatar
                                          sx={{ bgcolor: 'red' }}
                                          src={
                                            sim?.nhamang === ('Mobifone' || 'mobifone')
                                              ? images.mobifone_small
                                              : sim?.nhamang === ('Viettel' || 'viettel')
                                              ? images.viettel_small
                                              : sim?.nhamang === ('Vinaphone' || 'vinaphone')
                                              ? images.vinaphone_small
                                              : sim?.nhamang === ('Itelecom' || 'itelecom' || 'Itel' || 'itel')
                                              ? images.itel_small
                                              : sim?.nhamang === ('Vietnamobile' || 'vietnamobile' || 'vnmb')
                                              ? images.vnmb_small
                                              : sim?.nhamang === ('Wintel' || 'Wintel')
                                              ? images.Wintel_small
                                              : ''
                                          }
                                        >
                                          {' '}
                                          <SimCardIcon />{' '}
                                        </Avatar>
                                        <Stack direction="column" justifyContent="flex-end">
                                          <Stack direction="row" justifyContent="flex-end">
                                            <b
                                              className={cx(
                                                sim.nhamang === ('Mobifone' || 'mobifone')
                                                  ? 'title-sim-df'
                                                  : sim.nhamang === ('Vinaphone' || 'vinaphone')
                                                  ? 'title-sim-blue'
                                                  : sim.nhamang === ('Itelecom' || 'itelecom' || 'Itel' || 'itel')
                                                  ? 'title-sim-red'
                                                  : sim.nhamang === ('Vietnamobile' || 'vietnamobile' || 'vnmb')
                                                  ? 'title-sim-gold'
                                                  : 'title-sim-red',
                                              )}
                                            >
                                              {sim.sim_show}
                                            </b>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={0.5}
                                            justifyContent="flex-end"
                                            alignItems="center"
                                          >
                                            {/* <b>Giá sale:</b> */}
                                            {/* <b className={cx('price-old-item')}>
                                              {sim.priceOld && numberFormatText(sim.priceOld)}
                                            </b> */}

                                            {/* {favourite(sim.simSearch) && <FavoriteIcon sx={{ mr: 1 }} color="error" />} */}
                                            <b>Giá:</b>
                                            <b>{sim.price && numberFormatText(sim.price)}</b>
                                          </Stack>
                                        </Stack>
                                      </Stack>
                                    </div>
                                  </CardContent>
                                </Card>
                              </Paper>
                              {/* )} */}
                            </Grid>
                          ),
                      )}
                    {(layoutSim === '6' || layoutSim === '7') &&
                      simInfinteResult &&
                      simInfinteResult?.data?.map(
                        (sim, index) =>
                          sim.sim && (
                            <Grid key={index} item xs={6} md={4}>
                              {/* {layoutSim === '2' && ( */}
                              <Paper
                                elevation={2}
                                className={cx(isSimDaily ? 'active-boder-sim' : '', 'border-radius-10')}
                              >
                                <Card variant="outlined" className={cx('border-radius-10', 'border-0')}>
                                  <CardContent
                                    sx={{ p: 0, '&:last-child': { pb: 0 } }}
                                    component={Link}
                                    to={`/s/${sim.sim}`}
                                  >
                                    <FrameInfoSim
                                      bgrFrame={
                                        sim?.nhamang === ('Mobifone' || 'mobifone')
                                          ? 'df'
                                          : sim?.nhamang === ('Vinaphone' || 'vinaphone')
                                          ? 'blue'
                                          : sim?.nhamang === ('Itelecom' || 'itelecom' || 'Itel' || 'itel')
                                          ? 'red'
                                          : sim.nhamang === ('Vietnamobile' || 'vietnamobile' || 'vnmb')
                                          ? 'gold'
                                          : 'red'
                                      }
                                      title={sim.sim_show}
                                      arrDetailSim={handleDetailSim(
                                        sim?.nhamang,
                                        sim?.thuebao,
                                        sim?.sim,
                                        sim?.id_catesim,
                                        sim?.price,
                                        sim?.format_sim,
                                        sim?.menh_sim,
                                      )}
                                    />

                                    <div className={cx('detail-item-price')}>
                                      <Stack direction="row" spacing={1}>
                                        <b>Giá sale:</b>
                                        <b className={cx('price-item')}>{sim.price && numberFormatText(sim.price)}</b>
                                      </Stack>
                                      {sim.price_old ? (
                                        <Stack direction="row" spacing={0.5}>
                                          <b className={cx('price-old-item')}>
                                            {sim.price_old && numberFormatText(sim.price_old)}
                                          </b>
                                          <span className={cx('font-size-tool-sale', 'space-icon-sale')}>
                                            <LoyaltyTwoToneIcon
                                              className={cx('font-size-tool-sale-icon')}
                                              color="error"
                                            />
                                            <b>{sim.price_old && totalDiscount(sim.price, sim.price_old)}</b>
                                          </span>
                                        </Stack>
                                      ) : null}
                                    </div>

                                    <div className={cx('detail-item-note-height')}>
                                      <div className={cx('detail-item-note')}>
                                        {sim.note_sim ? sim.note_sim : `Uy Tín - Nhanh Gọn - Chất Lượng`}
                                      </div>
                                    </div>
                                  </CardContent>
                                </Card>
                                <Stack direction="row" spacing={0} justifyContent="flex-end">
                                  <div className={cx('icon-checkbox')}>
                                    {favourite(sim.sim) && <FavoriteIcon sx={{ mr: 1 }} color="error" />}
                                    {!checkCoppy[index] && (
                                      <CheckBoxOutlineBlankIcon
                                        sx={{ mr: 1 }}
                                        color="info"
                                        onClick={() => handleCoppy(index, sim)}
                                      />
                                    )}
                                    {checkCoppy[index] && (
                                      <CheckBoxIcon
                                        sx={{ mr: 1 }}
                                        color="info"
                                        onClick={() => handleDeleteCoppy(index)}
                                      />
                                    )}
                                  </div>
                                  {sim.price && authDomain['GMAIL_NOTIFI'] ? (
                                    <Stack direction={'row'} spacing={1} sx={{ m: 0.2 }}>
                                      <AddShoppingCartIcon
                                        color="success"
                                        onClick={() => onAddShop(sim.sim, sim.price, 'sim', 1, sim.sim)}
                                      />
                                      <ButtonUI
                                        color="error"
                                        borderBtn="#ff0000"
                                        borderRadius={10}
                                        small
                                        component={Link}
                                        to={`/s/${sim.sim}`}
                                      >
                                        Mua Ngay
                                      </ButtonUI>
                                    </Stack>
                                  ) : null}
                                </Stack>
                              </Paper>

                              {/* )} */}
                            </Grid>
                          ),
                      )}

                    {layoutSim === '4' && (
                      <Grid item xs={12} md={12}>
                        <div style={{ marginTop: 5, display: 'flex', justifyContent: 'center' }}>
                          {simInfinteResult?.data?.length > 0 && (
                            <table className={cx(`table-black`)}>
                              <thead>
                                <tr>
                                  <Hidden only={['sm', 'xs']}>
                                    <th className={cx(`th-black`, 'dis-center-2')}>
                                      {!isloadingCoppyAll ? (
                                        <Checkbox
                                          checked={Object.values(checkCoppy).length > 0 ? true : false}
                                          onClick={handleCoppyAll}
                                          sx={{
                                            color: 'gold',
                                            padding: '0px',
                                            '&.Mui-checked': {
                                              color: 'gold',
                                            },
                                          }}
                                        />
                                      ) : (
                                        <CircularProgress className={cx('loading-custom-gold')} />
                                      )}

                                      {/* {!isSimDaily && simInfinteResultCount?.totalRows > 0 ? `Sim Đẹp` : `Chọn Số Đẹp`} */}
                                    </th>
                                  </Hidden>
                                  <th className={cx(`th-black`)}>SIM SỐ</th>
                                  <th className={cx(`th-black`)}>GIÁ SALE</th>
                                  <th className={cx(`th-black`)}>MẠNG</th>
                                  <Hidden only={['md', 'xl', 'lg']}>
                                    {authDomain['GMAIL_NOTIFI'] ? <th className={cx(`th-black`)}>MUA SIM</th> : null}
                                  </Hidden>
                                  <Hidden only={['sm', 'xs']}>
                                    <th className={cx(`th-black`)}> DẠNG SIM</th>
                                    {authDomain['GMAIL_NOTIFI'] ? <th className={cx(`th-black`)}>MUA SIM</th> : null}
                                  </Hidden>
                                </tr>
                              </thead>

                              <tbody>
                                {simInfinteResult?.data?.map(
                                  (sim, index) =>
                                    sim.sim && (
                                      <tr key={index}>
                                        <Hidden only={['sm', 'xs']}>
                                          {sim?.sim && (
                                            <td
                                              className={cx('text-center', 'dis-center-2')}
                                              style={{ padding: '2px' }}
                                            >
                                              {!checkCoppy[index] && (
                                                <CheckBoxOutlineBlankIcon
                                                  sx={{ mr: 1 }}
                                                  color="info"
                                                  onClick={() => handleCoppy(index, sim)}
                                                />
                                              )}
                                              {checkCoppy[index] && (
                                                <CheckBoxIcon
                                                  sx={{ mr: 1 }}
                                                  color="info"
                                                  onClick={() => handleDeleteCoppy(index)}
                                                />
                                              )}
                                            </td>
                                          )}
                                        </Hidden>
                                        {sim?.sim && (
                                          <td
                                            onClick={() => {
                                              navigate(`/s/${sim.sim}`);
                                            }}
                                          >
                                            <b>{sim?.sim_show}</b>
                                          </td>
                                        )}
                                        <td
                                          onClick={() => {
                                            navigate(`/s/${sim.sim}`);
                                          }}
                                        >
                                          {' '}
                                          <b>{sim.price && numberFormatText(sim.price)}</b>
                                        </td>
                                        <td
                                          className={cx('text-center')}
                                          onClick={() => {
                                            navigate(`/s/${sim.sim}`);
                                          }}
                                        >
                                          <b
                                            style={{
                                              color:
                                                sim?.nhamang === ('Mobifone' || 'mobifone')
                                                  ? '#0967c5'
                                                  : sim?.nhamang === ('Vinaphone' || 'vinaphone')
                                                  ? '#229fd9'
                                                  : sim?.nhamang === ('Itelecom' || 'itelecom' || 'Itel' || 'itel')
                                                  ? '#d70018'
                                                  : sim.nhamang === ('Vietnamobile' || 'vietnamobile' || 'vnmb')
                                                  ? '#eb8400'
                                                  : '#d70018',
                                            }}
                                          >
                                            {sim?.nhamang}
                                          </b>
                                        </td>
                                        <Hidden only={['md', 'xl', 'lg']}>
                                          {sim.price && authDomain['GMAIL_NOTIFI'] ? (
                                            <td className={cx('text-center')}>
                                              <Stack direction={'row'} spacing={1} sx={{ m: 0.2 }}>
                                                <AddShoppingCartIcon
                                                  color="success"
                                                  onClick={() => onAddShop(sim.sim, sim.price, 'sim', 1, sim.sim)}
                                                />
                                                <ButtonUI
                                                  color="error"
                                                  borderBtn="#ff0000"
                                                  borderRadius={10}
                                                  small
                                                  component={Link}
                                                  to={`/s/${sim.sim}`}
                                                >
                                                  Mua Ngay
                                                </ButtonUI>
                                              </Stack>
                                            </td>
                                          ) : null}
                                        </Hidden>
                                        <Hidden only={['sm', 'xs']}>
                                          {/* <td
                                            onClick={() => {
                                              navigate(`/${sim.sim}`, { replace: true });
                                            }}
                                          >
                                            <b>{sim?.thuebao || 'Trả Trước'}</b>
                                          </td> */}
                                          <td
                                            onClick={() => {
                                              navigate(`/s/${sim.sim}`);
                                            }}
                                          >
                                            <b
                                              dangerouslySetInnerHTML={{
                                                __html: handleNameCate(
                                                  sim.sim,
                                                  sim?.id_catesim,
                                                  sim?.menh_sim,
                                                  sim?.format_sim,
                                                ),
                                              }}
                                            >
                                              {/* {handleNameCate(sim.sim, sim?.id_catesim, sim?.menh_sim, sim?.format_sim)} */}
                                            </b>
                                          </td>
                                          {sim.price && authDomain['GMAIL_NOTIFI'] ? (
                                            <td className={cx('text-center')}>
                                              <Stack direction={'row'} spacing={1} sx={{ m: 0.2 }}>
                                                <AddShoppingCartIcon
                                                  color="success"
                                                  sx={{ cursor: 'pointer' }}
                                                  onClick={() => onAddShop(sim.sim, sim.price, 'sim', 1, sim.sim)}
                                                />
                                                <ButtonUI
                                                  color="error"
                                                  borderBtn="#ff0000"
                                                  borderRadius={10}
                                                  small
                                                  component={Link}
                                                  to={`/s/${sim.sim}`}
                                                >
                                                  Mua Ngay
                                                </ButtonUI>
                                              </Stack>
                                            </td>
                                          ) : null}
                                        </Hidden>
                                      </tr>
                                    ),
                                )}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </Grid>
                    )}

                    {layoutSim === '8' &&
                      simInfinteResult &&
                      simInfinteResult?.data?.map(
                        (sim, index) =>
                          sim.sim && (
                            <Grid key={index} item xs={12} md={6}>
                              {/* {layoutSim === '1' && ( */}
                              <Paper elevation={0}>
                                <Card variant="outlined" sx={{ borderRadius: 3 }}>
                                  <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                                    <div className={cx('detail-item-price')}>
                                      {/* <Stack direction="row" alignItems="center"> */}

                                      <Stack direction="column" justifyContent="flex-end" sx={{ p: '2px' }}>
                                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                                          <span
                                            className={cx(
                                              sim.nhamang === ('Mobifone' || 'mobifone')
                                                ? 'title-sim-df'
                                                : sim.nhamang === ('Vinaphone' || 'vinaphone')
                                                ? 'title-sim-blue'
                                                : sim.nhamang === ('Itelecom' || 'itelecom' || 'Itel' || 'itel')
                                                ? 'title-sim-red'
                                                : sim.nhamang === ('Vietnamobile' || 'vietnamobile' || 'vnmb')
                                                ? 'title-sim-gold'
                                                : 'title-sim-red',
                                              'dis-center',
                                            )}
                                          >
                                            {!checkCoppy[index] && (
                                              <CheckBoxOutlineBlankIcon
                                                sx={{ mr: 1 }}
                                                color="action"
                                                onClick={() => handleCoppy(index, sim)}
                                              />
                                            )}
                                            {checkCoppy[index] && (
                                              <CheckBoxIcon
                                                sx={{ mr: 1 }}
                                                color="action"
                                                onClick={() => handleDeleteCoppy(index)}
                                              />
                                            )}
                                            <b
                                              onClick={() => {
                                                navigate(`/s/${sim.sim}`);
                                              }}
                                            >
                                              {' '}
                                              {sim.sim_show}
                                            </b>
                                          </span>
                                          <b
                                            style={{ fontSize: '16px' }}
                                            onClick={() => {
                                              navigate(`/s/${sim.sim}`);
                                            }}
                                          >
                                            {/* <b className={cx('price-old-item')}>
                                              {sim.price_old && numberFormatText(sim.price_old)}
                                            </b> */}
                                            {sim.price && numberFormat(sim.price)}₫
                                          </b>
                                        </Stack>
                                        <Stack
                                          direction="row"
                                          spacing={0.5}
                                          justifyContent="space-between"
                                          alignItems="center"
                                          onClick={() => {
                                            navigate(`/s/${sim.sim}`);
                                          }}
                                        >
                                          <b style={{ marginLeft: '35px' }}>{sim.nhamang && sim.nhamang}</b>

                                          {/* {favourite(sim.simSearch) && <FavoriteIcon sx={{ mr: 1 }} color="error" />} */}
                                          {/* <b>Giá:</b> */}
                                          <b>{sim.thuebao && sim.thuebao}</b>
                                        </Stack>
                                      </Stack>
                                      {/* </Stack> */}
                                    </div>
                                  </CardContent>
                                </Card>
                              </Paper>
                              {/* )} */}
                            </Grid>
                          ),
                      )}
                  </Grid>
                  {!loadinglist &&
                    !isSimDaily &&
                    simInfinteResult &&
                    simInfinteResult.data.length !== 0 &&
                    simInfinteResult.data.length <= 10 &&
                    getLocalStorage('khoso')?.is_simdaily > 0 && (
                      <div className={cx('xem-them-sim')}>
                        <ButtonUI
                          startIcon={<SimCardRoundedIcon />}
                          bgrColorBtn={bgrDF.bgr ? bgrDF.bgr + '26' : '#F3F4F6'}
                          colorText={bgrDF.bgr ? bgrDF.bgr : '#444444'}
                          borderBtn={bgrDF.bgr ? bgrDF.bgr + '4a' : '#44444447'}
                          onClick={() => {
                            searchSimDaily(
                              textSearch,
                              actionNetWork,
                              '',
                              actionRangePriceSim,
                              actionBtnSort,
                              simInfinteResult,
                              1,
                            );
                          }}
                        >
                          XEM THÊM SIM
                        </ButtonUI>
                      </div>
                    )}
                  <div ref={loader}>
                    {loadinglist && (
                      <div className={cx('css-load-sim')}>
                        <CircularProgress color="inherit" size={20} />
                        <b className={cx('pl-1')}>Tải dữ liệu...</b>
                      </div>
                    )}
                  </div>
                  {simInfinteResult?.data.length >= 50 && (
                    <Grid
                      container
                      className={cx('bounce2')}
                      sx={{ mt: 2, justifyContent: 'center', cursor: 'pointer' }}
                      onClick={submitSearchSim}
                    >
                      <ArrowCircleDownRoundedIcon className={cx('color-cs')} sx={{ fontSize: '2.5rem' }} />
                    </Grid>
                  )}
                </div>
              </div>
              {openCart && <SimpleSnackbar Notifi="Đã thêm vào giỏ hàng" />}

              {authDomain['background'] && (
                <Hidden only={['md', 'xl', 'lg']}>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <div className={cx(`header-title-grey-layout2`)}>
                        <div className={cx(`header-title-item-grey-layout2`)}>
                          <PersonSearchIcon sx={{ pr: '5px' }} fontSize="small" /> <b> TÌM SIM THEO NĂM SINH</b>
                        </div>
                        <div className={cx('detail-item-sim')}>
                          <Stack direction={'row'} spacing={1}>
                            <TextField
                              label="Ngày"
                              value={textDDMMYY.day}
                              onChange={(e) => {
                                setTextDDMMYY({ ...textDDMMYY, day: e.target.value });
                              }}
                              size="small"
                            />
                            <TextField
                              label="Tháng"
                              value={textDDMMYY.month}
                              size="small"
                              onChange={(e) => {
                                setTextDDMMYY({ ...textDDMMYY, month: e.target.value });
                              }}
                            />
                            <TextField
                              label="Năm"
                              value={textDDMMYY.year}
                              size="small"
                              onChange={(e) => {
                                setTextDDMMYY({ ...textDDMMYY, year: e.target.value });
                              }}
                            />
                          </Stack>
                          <Stack direction={'row'} sx={{ mt: 1 }}>
                            <ButtonUI
                              startIcon={<PersonSearchIcon />}
                              fullWidth
                              bgrColorBtn={bgrDF.bgr + '26'}
                              colorText={bgrDF.bgr}
                              borderBtn={bgrDF.bgr + '4a'}
                              onClick={() => {
                                setCheckCoppy({});
                                setDataImageExcel([]);
                                handleSearchNAMSINH();
                              }}
                            >
                              TÌM KIẾM
                            </ButtonUI>
                          </Stack>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Hidden>
              )}

              {resultNews.length > 0 && (
                <Hidden only={['md', 'xl', 'lg']}>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <div className={cx(`header-title-grey-layout2`)}>
                        <div className={cx(`header-title-item-grey-layout2`)}>
                          <FeedIcon sx={{ pr: '5px' }} fontSize="small" /> <b> TIN TỨC SIM</b>
                        </div>
                        {resultNews.map((news, index) => (
                          <div key={index} className={cx('detail-item-sim')}>
                            <div
                              className={cx('title-item')}
                              onClick={() => {
                                navigate('/bai-viet/' + convertToSlug(news.title) + `-${news.id}`, { replace: true });
                              }}
                            >
                              {news.title}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Grid>
                  </Grid>
                </Hidden>
              )}
              {isScroll && (
                <div>
                  <Box
                    style={{
                      position: 'fixed',
                      bottom: 320,
                      right: 10,
                      display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                      zIndex: 10,
                    }}
                  >
                    <TextField
                      label="Thêm icon:"
                      size="small"
                      sx={{ width: '100px', background: '#fff' }}
                      variant="outlined"
                      value={iconCopy}
                      color="error"
                      onChange={(e) => {
                        setIconCopy(e.target.value);
                        handleCoppyIcon(e);
                      }}
                      onClick={() => handleIsFocus(true)}
                      focused
                    />
                  </Box>
                  <CopyToClipboard text={textCopiedSimAndPrice} onCopy={() => setIsCopied(true)}>
                    <ButtonUI
                      borderBtn="#bef5ff"
                      color="info"
                      style={{
                        position: 'fixed',
                        bottom: 280,
                        right: 10,
                        display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                        zIndex: 10,
                      }}
                      onClick={submitBtnCopied}
                    >
                      Copy {Object.values(checkCoppy).length} sim và giá
                    </ButtonUI>
                  </CopyToClipboard>
                  <CopyToClipboard text={textCopied} onCopy={() => setIsCopied(true)}>
                    <ButtonUI
                      borderBtn="#bef5ff"
                      color="info"
                      style={{
                        position: 'fixed',
                        bottom: 240,
                        right: 10,
                        display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                        zIndex: 10,
                      }}
                      onClick={submitBtnCopied}
                    >
                      Copy {Object.values(checkCoppy).length} sim chi tiết
                    </ButtonUI>
                  </CopyToClipboard>
                  <CopyToClipboard text={textCopiedSim} onCopy={() => setIsCopied(true)}>
                    <ButtonUI
                      borderBtn="#bef5ff"
                      color="info"
                      style={{
                        position: 'fixed',
                        bottom: 200,
                        right: 10,
                        display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                        zIndex: 10,
                      }}
                      onClick={submitBtnCopied}
                    >
                      Copy {Object.values(checkCoppy).length} sim
                    </ButtonUI>
                  </CopyToClipboard>
                  <CopyToClipboard text={textCopiedChot} onCopy={() => setIsCopied(true)}>
                    <ButtonUI
                      borderBtn="#d1fae5"
                      color="success"
                      style={{
                        position: 'fixed',
                        bottom: 160,
                        right: 10,
                        display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                        zIndex: 10,
                      }}
                      onClick={submitBtnCopied}
                    >
                      Mẫu đặt sim
                    </ButtonUI>
                  </CopyToClipboard>
                  <ButtonUI
                    borderBtn="#f9a6a6"
                    colorText="#fff"
                    bgrColorBtn="#e10000"
                    style={{
                      position: 'fixed',
                      bottom: 70,
                      right: 10,
                      display: pos ? 'flex' : 'none',
                      zIndex: 10,
                    }}
                    onClick={handleTop}
                  >
                    <ArrowCircleUpSharpIcon size="medium" />
                  </ButtonUI>

                  {/* {authDomain?.background && (
                    <ButtonUI
                      colorText="#fff"
                      borderBtn="gold"
                      style={{
                        position: 'fixed',
                        bottom: pos ? 105 : 80,
                        right: 10,
                        display: 'flex',
                        zIndex: 10,
                        borderRadius: '20px',
                        background: `var(--home-color)`,
                      }}
                      onClick={() => {
                        setOpenShare(true);
                      }}
                    >
                      <ShareIcon
                        size="medium"
                        sx={{ border: '2px solid gold', color: 'gold', borderRadius: 10, p: 0.5, mr: 0.5 }}
                      />
                      <b style={{ lineHeight: '1.5' }}>
                        chia sẻ <br /> BẠN BÈ
                      </b>
                    </ButtonUI>
                  )} */}
                </div>
              )}
              {isCloseImageMenhSim && (
                <Grid container>
                  <Grid
                    item
                    xs={3}
                    md={1}
                    style={{
                      position: 'fixed',
                      bottom: '35%',
                      right: 3,
                      display: 'flex',
                      zIndex: 0,
                      borderRadius: '10px',
                      border: `1px dash var(--home-color)`,
                      padding: '0px',
                      opacity: isScroll ? 1 : 0.5,
                      flexDirection: 'column-reverse',
                    }}
                  >
                    <Stack direction={'column-reverse'}>
                      <Stack direction={'row'} spacing={0.5}>
                        <div
                          className={cx('animated-button-menhsim')}
                          onClick={() => {
                            navigate('/ngu-hanh-tuong-sinh', { replace: true });
                          }}
                        >
                          {' '}
                          <img
                            src={images.menhsim}
                            alt="menhsim"
                            style={{
                              borderRadius: '5px',
                              width: '100%',
                            }}
                          />{' '}
                          <span></span> <span></span> <span></span> <span></span>{' '}
                        </div>
                        <Stack direction={'column'}>
                          <CancelIcon
                            size="medium"
                            // sx={{ color: 'red' }}
                            onClick={() => {
                              setIsCloseImageMenhSim(false);
                            }}
                          />
                          <b
                            style={{
                              background: `var(--home-color)`,
                              width: '80%',
                              padding: '2px',
                              borderRadius: '5px',
                              color: '#fff',
                              marginBottom: '3px',
                              fontSize: '10px',
                              border: '1px solid gold',
                            }}
                            onClick={() => {
                              navigate('/ngu-hanh-tuong-sinh', { replace: true });
                            }}
                          >
                            Sim Hợp Mệnh
                          </b>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </div>
            <Dialog open={openShare} onClose={handleClose}>
              <DialogTitle>{'Chia sẻ link (ấn vào để copy):'}</DialogTitle>
              <DialogContent>
                <TextField
                  label="ấn vào để copy"
                  sx={{ m: 1 }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{`Link:`}</InputAdornment>,
                  }}
                  size="small"
                  value={window.location.href.replace('/?', '?')}
                  error
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    // alert('Đã copy link hãy gửi cho bạn bè!');
                    handleClose();
                  }}
                />
              </DialogContent>
            </Dialog>
          </Grid>

          {!authDomain['background'] && (
            <Hidden only={['sm', 'xs']}>
              <Grid item md={3}>
                <Grid item md={12}>
                  <div className={cx(`header-title-grey-layout2`)}>
                    <div className={cx(`header-title-item-grey-layout2`)}>
                      <PersonSearchIcon sx={{ pr: '5px' }} fontSize="small" /> <b> TÌM SIM THEO NĂM SINH</b>
                    </div>
                    {loadingTheme && <Skeleton variant="text" />}
                    {loadingTheme && <Skeleton variant="text" />}
                    {loadingTheme && <Skeleton variant="text" />}
                    {loadingTheme && <Skeleton variant="text" />}
                    {loadingTheme && <Skeleton variant="text" />}
                  </div>
                </Grid>
              </Grid>
            </Hidden>
          )}
          {/* CỘT BÊN PHẢI */}
          {authDomain['background'] && (
            <Hidden only={['sm', 'xs']}>
              <Grid item md={3}>
                <Grid item md={12}>
                  {authDomain?.bank && (
                    <div className={cx(`header-title-grey-layout2`)}>
                      <div className={cx(`header-title-item-grey-layout2`)}>
                        <AccountBalanceWalletRoundedIcon sx={{ pr: '5px' }} fontSize="small" />{' '}
                        <b> TÀI KHOẢN NGÂN HÀNG</b>
                      </div>
                      <div>
                        <Stack direction={'column'} sx={{ m: 1 }} spacing={0.5}>
                          <b>{authDomain?.bank?.split(',')[0]}</b>
                          <span>
                            Chủ tài khoản: <b>{authDomain?.bank?.split(',')[1]}</b>
                          </span>
                          <span>
                            Số tài khoản: <b>{authDomain?.bank?.split(',')[2]}</b>
                          </span>
                        </Stack>
                      </div>
                    </div>
                  )}

                  {resultNews.length > 0 && (
                    <div className={cx(`header-title-grey-layout2`)}>
                      <div className={cx(`header-title-item-grey-layout2`)}>
                        <FeedIcon sx={{ pr: '5px' }} fontSize="small" /> <b> TIN TỨC SIM</b>
                      </div>
                      {resultNews.map((news, index) => (
                        <div key={index} className={cx('detail-item-sim')}>
                          <div
                            className={cx('title-item')}
                            onClick={() => {
                              navigate('/bai-viet/' + convertToSlug(news.title) + `-${news.id}`, { replace: true });
                            }}
                          >
                            {news.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  <div className={cx(`header-title-grey-layout2`)}>
                    <div className={cx(`header-title-item-grey-layout2`)}>
                      <PersonSearchIcon sx={{ pr: '5px' }} fontSize="small" /> <b> TÌM SIM THEO NĂM SINH</b>
                    </div>
                    <div className={cx('detail-item-sim')}>
                      <Stack direction={'row'} spacing={1}>
                        <TextField
                          label="Ngày"
                          value={textDDMMYY.day}
                          onChange={(e) => {
                            setTextDDMMYY({ ...textDDMMYY, day: e.target.value });
                          }}
                          size="small"
                        />
                        <TextField
                          label="Tháng"
                          value={textDDMMYY.month}
                          size="small"
                          onChange={(e) => {
                            setTextDDMMYY({ ...textDDMMYY, month: e.target.value });
                          }}
                        />
                        <TextField
                          label="Năm"
                          value={textDDMMYY.year}
                          size="small"
                          onChange={(e) => {
                            setTextDDMMYY({ ...textDDMMYY, year: e.target.value });
                          }}
                        />
                      </Stack>
                      <Stack direction={'row'} sx={{ mt: 1 }}>
                        <ButtonUI
                          startIcon={<PersonSearchIcon />}
                          fullWidth
                          bgrColorBtn={bgrDF.bgr + '26'}
                          colorText={bgrDF.bgr}
                          borderBtn={bgrDF.bgr + '4a'}
                          onClick={() => {
                            setCheckCoppy({});
                            setDataImageExcel([]);
                            handleSearchNAMSINH();
                          }}
                        >
                          TÌM KIẾM
                        </ButtonUI>
                      </Stack>
                    </div>
                  </div>

                  <div className={cx(`header-title-grey-layout2`)}>
                    <div className={cx(`header-title-item-grey-layout2`)}>
                      <SimCardIcon sx={{ pr: '5px' }} fontSize="small" /> <b> SIM THEO MỆNH</b>
                    </div>
                    {arrayMenhSim.map((menh, index) => (
                      <div
                        key={index}
                        className={cx('detail-item-sim', `${actionMenhSim === menh.link ? 'bgr-active' : ''}`)}
                        onClick={() => {
                          menh.link !== actionMenhSim ? setActionMenhSim(menh.link) : setActionMenhSim(0);
                          fetchApiLocSim(
                            textSearch,
                            actionNetWork,
                            actionMenuCate,
                            actionFormatSim,
                            actionMenhSim === menh.link ? '' : menh.link,
                            actionBtnSort,
                            actionRangePriceSim,
                            actionTranhso.join(''),
                            thuebao,
                          );
                        }}
                      >
                        <div className={cx('title-item')}>Sim {menh.title}</div>
                      </div>
                    ))}
                  </div>

                  <div className={cx(`header-title-grey-layout2`)}>
                    <div className={cx(`header-title-item-grey-layout2`)}>
                      <SimCardIcon sx={{ pr: '5px' }} fontSize="small" /> <b> SIM THEO THỂ LOẠI</b>
                    </div>
                    {menuSimResult.map((menu, index) => (
                      <div
                        key={index}
                        className={cx('detail-item-sim', `${actionMenuCate === menu.link ? 'bgr-active' : ''}`)}
                        onClick={() => {
                          menu.link !== actionMenuCate ? setActionMenuCate(menu.link) : setActionMenuCate(0);
                          fetchApiLocSim(
                            textSearch,
                            actionNetWork,
                            actionMenuCate === menu.link ? '' : menu.link,
                            actionFormatSim,
                            actionMenhSim,
                            actionBtnSort,
                            actionRangePriceSim,
                            actionTranhso.join(''),
                            thuebao,
                          );
                        }}
                      >
                        <div className={cx('title-item')}>Sim {menu.title}</div>
                      </div>
                    ))}
                  </div>

                  <div className={cx(`header-title-grey-layout2`)}>
                    <div className={cx(`header-title-item-grey-layout2`)}>
                      <SimCardIcon sx={{ pr: '5px' }} fontSize="small" /> <b> SIM THEO MẠNG</b>
                    </div>
                    {homeNetWork.map((network, index) => (
                      <div
                        key={index}
                        className={cx(
                          'detail-item-sim',
                          `${actionNetWork === network.titleNetWork ? 'bgr-active' : ''}`,
                        )}
                        onClick={() => {
                          network.titleNetWork !== actionNetWork
                            ? setActionNetWork(network.titleNetWork)
                            : setActionNetWork('');
                          fetchApiLocSim(
                            textSearch,
                            actionNetWork === network.titleNetWork ? '' : network.titleNetWork,
                            actionMenuCate,
                            actionFormatSim,
                            actionMenhSim,
                            actionBtnSort,
                            actionRangePriceSim,
                            actionTranhso.join(''),
                            thuebao,
                          );
                        }}
                      >
                        <div className={cx('title-item')}>Sim {network.titleNetWork}</div>
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>
            </Hidden>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default HomeTwo;
